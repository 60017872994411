import styled from "styled-components";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";

export const StyledChip = styled.div<{ bgColor: string; borderRadius: number }>`
  background: ${({ bgColor }) => bgColor};
  border-radius: ${({ borderRadius }) => borderRadius + "px"};
  padding: 8px 24px;
  width: fit-content;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    gap: 8px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > p:nth-child(2) {
    width: 80%;
  }
  @media ${MEDIA_QUERIES.TAB_PORT} {
    gap: 16px;
    padding: 0px 24px;
    & > p:nth-child(2) {
      width: 100%;
    }
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 540px;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    padding: 32px 16px;
  }
`;

export const InvestInContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > h6 {
    margin-bottom: 12px;
  }
  & > div:nth-child(3) {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
  }
`;

export const PromoTwoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    gap: 32px;
  }
`;
