import { createAsyncThunk } from "@reduxjs/toolkit";
import { logException } from "../../helpers/logException/logException";
import { clientInvestSdk } from "@utils/clientInvestSdk";
import {
  CreateUserBankAccountInput,
  UpdateUserBankAccountInput,
  UserBankAccount,
} from "@ifgengineering/client-invest-sdk";
import { toast } from "react-toastify";

const GET_BANK_ACCOUNTS_API = "GET_BANK_ACCOUNTS_API";
const CREATE_BANK_ACCOUNTS_API = "CREATE_BANK_ACCOUNTS_API";
const UPDATE_BANK_ACCOUNTS_API = "UPDATE_BANK_ACCOUNTS_API";

export const fetchBankAccountsAPI = createAsyncThunk<UserBankAccount[]>(
  GET_BANK_ACCOUNTS_API,
  async () => {
    try {
      const res = await clientInvestSdk.getUserBankAccounts();
      return res.data;
    } catch (e: any) {
      if (e.response.status !== 401) {
        logException({
          tag: "fetchBankAccountsAPI",
          exception: e,
        });
      }

      return [];
    }
  }
);

export const createBankAccountAPI = createAsyncThunk(
  CREATE_BANK_ACCOUNTS_API,
  async (
    payload: { file: File; data: CreateUserBankAccountInput },
    { rejectWithValue, dispatch }
  ) => {
    const { file, data } = payload;
    try {
      const response = await clientInvestSdk.postUserBankAccount(file, data);

      if (response.status === 201) {
        toast.success("Bank account created successfully.");
        dispatch(fetchBankAccountsAPI());
      }

      return response;
    } catch (e: any) {
      if (e.response?.status !== 403) {
        toast.error("Something went wrong. Try again or contact support");
        logException({
          tag: "createBankAccountAPI",
          exception: e,
        });
      }

      return rejectWithValue(e);
    }
  }
);

export const updateBankAccountAPI = createAsyncThunk(
  UPDATE_BANK_ACCOUNTS_API,
  async (
    payload: { id: string; file?: File; data: UpdateUserBankAccountInput },
    { rejectWithValue, dispatch }
  ) => {
    const { id, file, data } = payload;
    try {
      const response = await clientInvestSdk.putUserBankAccount(id, data, file);

      if (response.status === 200) {
        toast.success("Bank account updated successfully.");
        dispatch(fetchBankAccountsAPI());
      }

      return response;
    } catch (e: any) {
      if (e.response?.status !== 403) {
        toast.error("Something went wrong. Try again or contact support");
        logException({
          tag: "updateBankAccountAPI",
          exception: e,
        });
      }

      return rejectWithValue(e);
    }
  }
);
