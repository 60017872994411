import React, { FC, useState } from "react";
import { navigate } from "gatsby";
import AppleLinkEmailForm from "./AppleLinkEmailForm";
import AppleLinkOTPForm from "./AppleLinkOTPForm";
import {
  AppleLinkStatusFailure,
  AppleLinkStatusSuccess,
} from "./AppleLinkStatus";

interface AppleLinkFlowProps {
  title: string;
  loginUrl: string;
  handleCancel: VoidFunction;
}

const AppleLinkFlow: FC<AppleLinkFlowProps> = ({
  handleCancel,
  title,
  loginUrl,
}) => {
  const [view, setView] = useState<"email" | "otp" | "success" | "failure">(
    "email"
  );
  const [email, setEmail] = useState("");

  switch (view) {
    case "failure":
      return (
        <AppleLinkStatusFailure onLoginWithEmail={() => navigate(loginUrl)} />
      );
    case "success":
      return (
        <AppleLinkStatusSuccess onContinue={() => navigate("/app/deals")} />
      );
    case "otp":
      return (
        <AppleLinkOTPForm
          title={title}
          email={email}
          handleBackButton={() => setView("email")}
          handleSuccess={() => setView("success")}
          handleFailure={() => setView("failure")}
        />
      );
    case "email":
    default:
      return (
        <AppleLinkEmailForm
          title={title}
          onClickBack={handleCancel}
          onChange={(email) => setEmail(email)}
          handleSuccess={() => setView("otp")}
        />
      );
  }
};

export default AppleLinkFlow;
