import { LabelTag, StyledLink } from "./styled";
import Icon from "@icon-park/react/es/all";
import { theme, Text } from "@ifgengineering/component-library";
import React from "react";
import { iconMap } from "./constants";
import { MenuTemplateItem } from "../types";
import styled from "styled-components";

const StyledIcon = styled(Icon)`
  display: flex;
`;

const LinkComponent: React.FC<Omit<MenuTemplateItem, "position">> = ({
  tab,
  onClick,
  selected,
  selectedIconAndTextColor = "SLATE800",
  selectedBgColor = "WHITE",
  label,
  labelTag,
  hideLabel = false,
  rounded = false,
  isSidebarExpanded,
  ...props
}) => {
  const textColor = selected ? selectedIconAndTextColor : "SLATE600";
  const iconColor = selected
    ? theme.colors[selectedIconAndTextColor]
    : theme.colors.SLATE600;

  const showExpandedLabelTag = Boolean(labelTag) && isSidebarExpanded;
  const showCollapsedLabelTag = Boolean(labelTag) && !isSidebarExpanded;
  const getLabelTagIconColor = (): string => {
    switch (true) {
      case selected && !hideLabel:
        return theme.colors.GREEN600;
      case selected:
        return theme.colors.GREEN400;
      default:
        return theme.colors.GREEN600;
    }
  };

  return (
    <StyledLink
      data-testid={`${label}-tab-link`}
      onClick={onClick}
      selected={selected}
      rounded={rounded}
      selectedBgColor={selectedBgColor}
      {...props}
    >
      <StyledIcon type={iconMap[tab] as string} size={20} fill={iconColor} />
      {!hideLabel && (
        <Text type="P14" color={textColor}>
          {label}
        </Text>
      )}
      {showExpandedLabelTag && (
        <LabelTag isSelected={selected}>
          <Text type="P12" color="GREEN400">
            {labelTag}
          </Text>
        </LabelTag>
      )}
      {showCollapsedLabelTag && (
        <StyledIcon
          type="Star"
          theme="filled"
          size={8}
          style={{
            position: "absolute",
            marginLeft: !hideLabel ? 70 : 33,
            top: !hideLabel ? 16 : 8,
          }}
          fill={getLabelTagIconColor()}
        />
      )}
    </StyledLink>
  );
};

const Link: React.FC<Omit<MenuTemplateItem, "position">> = ({
  href,
  ...props
}) => {
  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        <LinkComponent {...props} />
      </a>
    );
  } else {
    return <LinkComponent {...props} />;
  }
};

export default Link;
