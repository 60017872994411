import React, { useEffect, useState } from "react";
import { formatValue } from "react-currency-input-field";
import Container from "./styled/Container";
import ErrorContainer from "./styled/ErrorContainer";
import Icon from "@icon-park/react/es/all";
import { theme } from "../../styles";
import Text from "../Text";
import {
  InputWrapper,
  StyledGenericInput,
  CurrencySymbol,
} from "./styled/MoneyInput";
import { CURRENCY_SYMBOL_MAP, CurrencyEnumType } from "./types";

export type MoneyInputProps = {
  errorMessage?: string | null;
  heading?: string;
  height?: number;
  value?: string;
  max?: string;
  currency?: CurrencyEnumType;
  onChange: (value: string) => void;
};

const MoneyInput = ({
  errorMessage,
  heading,
  onChange,
  value = "",
  currency = "GBP",
  max,
  height,
}: MoneyInputProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const onFormatValue = (value: string) => {
    const hasDecimal = value.includes(".");
    const formattedNumber = hasDecimal ? Number(value).toFixed(2) : value;
    return formatValue({
      value: formattedNumber,
      groupSeparator: ",",
      decimalSeparator: ".",
    });
  };

  const currencySymbol = CURRENCY_SYMBOL_MAP[currency];
  const stripFormatting = (value: string) => {
    return value.replace(/,/g, "");
  };

  const [inputValue, setInputValue] = useState<string>(
    Number(value) > 0 ? onFormatValue(value) : value
  );

  const validateValue = (imputedValue: string) => {
    if (!max || !imputedValue) {
      return imputedValue;
    }
    return Number(imputedValue) > Number(max) ? max : imputedValue;
  };

  const onChangeHandler = (val: string) => {
    const isNumeric = /^\d*\.?\d*$/;
    if (isNumeric.test(val)) {
      const parts = val.split(".");

      if (parts.length === 2 && parts[1].length > 2) return;
      const validatedValue = validateValue(val);

      setInputValue(validatedValue);
      onChange(validatedValue || "");
    }
  };

  const onBlurHandler = () => {
    setIsEditing(false);
    setInputValue(onFormatValue(inputValue));
  };

  const onFocusHandler = () => {
    setIsEditing(true);
    setInputValue(stripFormatting(inputValue));
  };

  useEffect(() => {
    /**
     * The variable 'isEditing' indicates that the user is altering the value by typing directly into the field.
     * It's important to make this distinction, as the component also triggers an onChange() action in the parent component,
     * potentially causing an update loop and formatting issues.
     */
    if (!isEditing) {
      setInputValue(onFormatValue(value));
    }
  }, [value]);

  return (
    <Container height={height?.toString()}>
      {heading && (
        <Text type="S16" color="SLATE800">
          {heading}
        </Text>
      )}
      <InputWrapper>
        <CurrencySymbol>{currencySymbol}</CurrencySymbol>
        <StyledGenericInput
          data-testid="currency-input"
          hasError={!!errorMessage}
          value={inputValue}
          onChange={(e) => onChangeHandler(e.target.value)}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
        />
      </InputWrapper>
      {errorMessage && (
        <ErrorContainer>
          <Icon
            type="Caution"
            size={14}
            fill={theme.colors.ERROR800}
            theme="filled"
          />
          <Text type="P14" color="ERROR800">
            {errorMessage}
          </Text>
        </ErrorContainer>
      )}
    </Container>
  );
};

export default MoneyInput;
