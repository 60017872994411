import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  VERSION,
  RECAPTCHA_CLIENT_KEY,
  SENTRY_DSN,
  GOOGLE_CLIENT_ID,
} from "gatsby-env-variables";
import "react-toastify/dist/ReactToastify.css";
import { persistor, store } from "./src/state/store";
import {
  axiosErrorInterceptor,
  axiosRequestInterceptor,
} from "./src/components/Login/helpers/interceptors";
import tagPlatform from "./src/helpers/tagPlatform";
import { AuthComponentsProvider } from "@ifgengineering/client-auth-components";
import { logException } from "./src/helpers/logException/logException";
import useAnalytics from "./src/helpers/useAnalytics";
import { resetPassword } from "./src/components/Login/actions";
import { authSdk } from "./src/utils/auth/auth";
import { GlobalStyle } from "./src/styles/global-style";
import { initSentry } from "./src/helpers/logException/sentryInit";
import { PersistGate } from "redux-persist/lib/integration/react";
import { getUserInfoAsync } from "./src/state/auth/auth.actions";
import { fetchRemoteConfigThunk } from "./src/state/remoteConfiguration/remoteConfiguration.actions";
import { GoogleOAuthProvider } from "@react-oauth/google";

// change to trigger flow
const { passwordlessVerify, passwordlessStart, generateOtp, appleLinkEmail } =
  authSdk;

const Startup = ({ element }) => {
  console.log("VERSION: ", VERSION);
  tagPlatform();
  initSentry(SENTRY_DSN);

  const loadRemoteConfig = async () => {
    await store.dispatch(fetchRemoteConfigThunk());
  };

  const passwordlessVerifyAction = async (email, otp) => {
    await passwordlessVerify(email, otp);
    await store.dispatch(getUserInfoAsync());
  };

  const appleLinkEmailAction = async (payload) => {
    try {
      await appleLinkEmail(payload);
      await store.dispatch(getUserInfoAsync());
    } catch (exception) {
      logException({
        exception,
        message: "Failed to Link Apple Email",
        scopeEmail: payload.existingAccountEmail,
        tag: "appleLinkEmailAction",
      });
    }
  };

  useEffect(() => {
    loadRemoteConfig();
    const authInterceptor = axiosErrorInterceptor();
    const requestInterceptor = axiosRequestInterceptor();
    if (window.DO_NOT_TRACK === true) {
      axios.defaults.headers.post["TRACKING"] = "DO_NOT_TRACK";
      axios.defaults.headers.common["TRACKING"] = "DO_NOT_TRACK";
    }

    axios.defaults.headers.post["x-application-id"] = "web-app";
    axios.defaults.headers.common["x-application-id"] = "web-app";

    axios.defaults.headers.post["x-application-version"] = VERSION;
    axios.defaults.headers.common["x-application-version"] = VERSION;

    return () => {
      axios.interceptors.request.eject(authInterceptor);
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Helmet>
          <title>App | Cur8</title>
          <meta name="description" content="App | Invest" />
          <html lang="en-GB" />
          <script
            type="text/javascript"
            src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            async
          />
          <script
            type="text/javascript"
            src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
            async
          />
        </Helmet>
        <AuthComponentsProvider
          isCur8InWealthEnabled={true}
          isLayoutBEnabled={true}
          isMagicCodeEnabled={true}
          logException={logException}
          recaptchaClientKey={RECAPTCHA_CLIENT_KEY}
          useAnalytics={useAnalytics}
          resetPassword={resetPassword}
          passwordlessVerify={passwordlessVerifyAction}
          passwordlessStart={passwordlessStart}
          generateOTP={generateOtp}
          appleLinkEmail={appleLinkEmailAction}
        >
          <GlobalStyle />
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            {element}
          </GoogleOAuthProvider>
        </AuthComponentsProvider>
      </PersistGate>
    </Provider>
  );
};

export const wrapRootElement = ({ element }) => {
  return <Startup element={element} />;
};

export const wrapPageElement = ({ element }) => {
  return <>{element}</>;
};

export const onRouteUpdate = () => {
  const scrollableElement = document.querySelector("#RightPanelContainer");

  if (scrollableElement) {
    scrollableElement.scrollTo(0, 0);
  }
};

export default { wrapRootElement, wrapPageElement };
