import styled from "styled-components";

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
