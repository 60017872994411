import {
  createBankAccountAPI,
  fetchBankAccountsAPI,
  updateBankAccountAPI,
} from "./bankAccount.actions";
import { BankAccountState } from "./bankAccount.types";

export const INITIAL_STATE: BankAccountState = {
  loading: false,
  neverLoaded: true,
  data: [],
};

const bankAccountReducer = (
  state = INITIAL_STATE,
  action: any
): BankAccountState => {
  switch (action.type) {
    case fetchBankAccountsAPI.pending.type:
    case createBankAccountAPI.pending.type:
    case updateBankAccountAPI.pending.type:
      return {
        ...state,
        loading: true,
      };
    case fetchBankAccountsAPI.fulfilled.type:
      return {
        ...state,
        loading: false,
        neverLoaded: false,
        data: action.payload,
      };
    case createBankAccountAPI.fulfilled.type:
    case updateBankAccountAPI.fulfilled.type:
      return {
        ...state,
        loading: false,
      };
    case fetchBankAccountsAPI.rejected.type:
    case createBankAccountAPI.rejected.type:
    case updateBankAccountAPI.rejected.type:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default bankAccountReducer;
