import React, { FC } from "react";
import { Field } from "formik";
import { theme, Text } from "@ifgengineering/component-library";
import { ErrorContainer, FieldStyled } from "./styled";
import Icon from "@icon-park/react/es/all";
import { Cur8OtpInput } from "../../../Cur8OtpInput/Cur8OtpInput.component";

interface MagicCodeInputProps {
  name: string;
  numInputs: number;
  isDisabled?: boolean;
  hasErrored?: boolean;
  errorMessage?: string;
}

export const MagicCodeInput: FC<MagicCodeInputProps> = ({
  name,
  numInputs,
  isDisabled,
  hasErrored,
  errorMessage,
}) => {
  return (
    <FieldStyled>
      <Field name={name}>
        {({
          field: { value },
          form: { setFieldValue },
        }: {
          field: { value: string };
          form: { setFieldValue: (name: string, otp: string) => void };
        }) => {
          return (
            <>
              <Cur8OtpInput
                value={value}
                numInputs={numInputs}
                onChange={(otp: string) => {
                  setFieldValue(name, otp);
                }}
                hasErrored={hasErrored}
                isDisabled={isDisabled}
              />
              {errorMessage && (
                <ErrorContainer>
                  <Icon
                    type="Caution"
                    size={14}
                    fill={theme.colors.ERROR800}
                    theme="filled"
                  />
                  <Text type="P14" color="ERROR800">
                    {errorMessage}
                  </Text>
                </ErrorContainer>
              )}
            </>
          );
        }}
      </Field>
    </FieldStyled>
  );
};

export default MagicCodeInput;
