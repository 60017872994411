import React, { useState } from "react";
import { NavBar } from "@ifgengineering/client-app-navbar";
import { useDispatch } from "react-redux";
import { signOutAsync } from "../../state/auth/auth.actions";
import { navItems } from "./navItems";

export const NavigationLayout: React.FC = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const signOutUser = () => dispatch(signOutAsync());

  return (
    <NavBar
      hideTopBarMobile
      hideTopBarDesktop
      template={navItems}
      isOpen={isMenuOpen}
      toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
      page="Invest"
      logoutCallback={signOutUser}
    >
      <>{children}</>
    </NavBar>
  );
};

export default NavigationLayout;
