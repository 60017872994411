import { AxiosPromise, AxiosStatic } from "axios";
import { Investor, NetPromoterScore } from "../types/investor";
import {
  AccreditationQuestion,
  AccreditationRequest,
} from "../types/Accreditation";
import {
  OnboardInvestorReturn,
  UpdateOnboardingAPIPayload,
} from "../types/Onboarding";

export const getInvestor =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (): AxiosPromise<Investor> =>
    await appAxios.get(`${serverURL}/investor`, {
      withCredentials: true,
    });

export const getAccreditationQuestionnaire =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (): AxiosPromise<AccreditationQuestion[]> =>
    await appAxios.get(`${serverURL}/investor/accreditation/questionnaire`, {
      withCredentials: true,
    });

export const reaccreditateInvestor =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (data: AccreditationRequest): AxiosPromise<AccreditationQuestion[]> =>
    await appAxios.post(`${serverURL}/investor/reaccreditation`, data, {
      withCredentials: true,
    });

export const onboardInvestor =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (
    data: UpdateOnboardingAPIPayload
  ): AxiosPromise<OnboardInvestorReturn> =>
    await appAxios.post(`${serverURL}/investor/onboarding/v2`, data, {
      withCredentials: true,
    });

export const postNPS =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (npsData: NetPromoterScore): AxiosPromise<NetPromoterScore> =>
    await appAxios.post(`${serverURL}/investor/nps`, npsData, {
      withCredentials: true,
    });
