import { logException } from "@helpers/logException/logException";
import {
  TNotification,
  TUpdateNotificationPayload,
} from "@ifgengineering/client-notifications-sdk";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { notificationsSdk } from "@utils/notificationsSdk";

const FETCH_NOTIFICATIONS_API = "FETCH_NOTIFICATIONS_API";
const PUT_NOTIFICATIONS_API = "PUT_NOTIFICATIONS_API";
const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";

export const fetchNotificationsThunk = createAsyncThunk<TNotification[]>(
  FETCH_NOTIFICATIONS_API,
  async (_, { rejectWithValue }) => {
    try {
      const response = await notificationsSdk.getNotifications();

      return response.data;
    } catch (e: any) {
      if (e.response.status !== 401) {
        logException({
          tag: "fetchNotificationsAPI",
          exception: e,
        });
      }

      return rejectWithValue(e.message);
    }
  }
);

export const putNotificationsThunk = createAsyncThunk<
  void,
  TUpdateNotificationPayload[]
>(PUT_NOTIFICATIONS_API, async (notifications, { rejectWithValue }) => {
  try {
    await notificationsSdk.updateNotifications(notifications);
  } catch (e: any) {
    if (e.response.status !== 401) {
      logException({
        tag: "putNotificationsAPI",
        exception: e,
      });
    }

    return rejectWithValue(e.message);
  }
});

export const updateNotificationsAction =
  createAction<TUpdateNotificationPayload[]>(UPDATE_NOTIFICATIONS);
