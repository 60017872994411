import styled from "styled-components";
import React, { FC } from "react";
import dayjs from "dayjs";
import { OutlinedButton, Text, theme } from "@ifgengineering/component-library";
import noLogoImage from "@assets/cur8/img/no-logo-deal.png";

const NotificationListItemStyled = styled.div`
  display: flex;
  padding: 20px;
  border-bottom-color: ${theme.colors.SLATE300};
  border-bottom-width: 1px;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  margin-left: 34px;
  flex-direction: column;
  gap: 10px;
`;

const Logo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: ${theme.colors.WHITE};
  margin-right: 10px;
  border: 1px solid ${theme.colors.SLATE300};
`;

const Title = styled.div`
  display: flex;
`;

const Time = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 200px;
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  & > button {
    width: auto;
    white-space: nowrap;
    border-radius: 24px;
  }
`;

type TProps = {
  icon?: string | null;
  sentAt: string;
  heading: string;
  description: string;
  ctaLabel?: string;
  onClick?: () => void;
};

const NotificationListItem: FC<TProps> = ({
  sentAt,
  description,
  heading,
  icon,
  ctaLabel,
  onClick,
}: TProps) => {
  const formattedSentAt = dayjs(sentAt).format("DD/MM hh:mm A");
  return (
    <NotificationListItemStyled>
      <Header>
        <Logo
          src={icon || noLogoImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = noLogoImage;
          }}
        />
        <Title>
          <Text type="S14" color="SLATE900">
            {heading}
          </Text>
        </Title>
      </Header>
      <Body>
        <Text type="P14" color="SLATE800">
          {description}
        </Text>
        <ButtonWrapper>
          {onClick && ctaLabel && (
            <OutlinedButton text={ctaLabel} onClick={onClick} />
          )}
          <Time>
            <Text type="P12" color="SLATE600">
              {formattedSentAt}
            </Text>
          </Time>
        </ButtonWrapper>
      </Body>
    </NotificationListItemStyled>
  );
};

export default NotificationListItem;
