import React, { useEffect, useState } from "react";
import AppleLinkFlow from "./AppleLinkFlow";
import SignupAppleChooseFlow from "./SignupAppleChooseFlow";
import { useClientAuthComponents } from "../../../Context";
import { navigate } from "gatsby";

interface SignupAppleProps {
  onRegister: VoidFunction;
  loginUrl?: string;
  signupUrl?: string;
}

const SignupApple: React.FC<SignupAppleProps> = ({
  onRegister,
  loginUrl = "/app/login",
  signupUrl = "/app/signup",
}) => {
  const { appleAuthData } = useClientAuthComponents();
  useEffect(() => {
    if (!appleAuthData?.isPrivateEmail) {
      navigate(loginUrl);
    }
  }, []);
  const [showAppleLinkFLow, setShowAppleLinkFLow] = useState<boolean>(false);
  const title = "Sign Up with Apple";

  if (showAppleLinkFLow) {
    return (
      <AppleLinkFlow
        title={title}
        loginUrl={loginUrl}
        handleCancel={() => setShowAppleLinkFLow(false)}
      />
    );
  }
  return (
    <SignupAppleChooseFlow
      title={title}
      loginUrl={loginUrl}
      signupUrl={signupUrl}
      onRegister={onRegister}
      onLink={() => setShowAppleLinkFLow(true)}
    />
  );
};

export default SignupApple;
