import { createSlice } from "@reduxjs/toolkit";
import { fetchRemoteConfigThunk } from "./remoteConfiguration.actions";

export type TRemoteConfig = {
  FF_LIVE_CHAT: boolean;
  USD_GBP_EXCHANGE_RATE: number;
  FF_INCOME_DECISION: boolean;
  FF_LIQUIDATION: boolean;
};

export type TRemoteConfigState = {
  loading: boolean;
  neverLoaded: boolean;
  data: TRemoteConfig;
};

export const DEFAULT_REMOTE_CONFIG_PARAMETERS = {
  FF_LIVE_CHAT: false,
  USD_GBP_EXCHANGE_RATE: 0.796,
  FF_INCOME_DECISION: false,
  FF_LIQUIDATION: false,
};

const INITIAL_STATE: TRemoteConfigState = {
  loading: false,
  neverLoaded: true,
  data: DEFAULT_REMOTE_CONFIG_PARAMETERS,
};

export const remoteConfigSlice = createSlice({
  name: "remoteConfig",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchRemoteConfigThunk.pending,
      (state: TRemoteConfigState) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchRemoteConfigThunk.rejected,
      (state: TRemoteConfigState) => {
        state.loading = false;
      }
    );
    builder.addCase(
      fetchRemoteConfigThunk.fulfilled,
      (state: TRemoteConfigState, { payload }) => {
        state.loading = false;
        state.neverLoaded = false;
        state.data = payload;
      }
    );
  },
});

export default remoteConfigSlice.reducer;
