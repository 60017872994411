import React, { useContext } from "react";
import { TabContext } from "./TabContext.component";

interface TabPanelProps {
  value: string;
  children: React.ReactNode;
}

export const TabPanel = ({
  value,
  children,
}: TabPanelProps): JSX.Element | null => {
  const { selectedTab } = useContext(TabContext);

  return value === selectedTab ? <div>{children}</div> : null;
};

export default TabPanel;
