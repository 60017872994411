import PortfolioActionTypes from "./portfolio.types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientInvestSdk } from "@utils/clientInvestSdk";
import { logException } from "@helpers/logException/logException";

export const fetchPortfolioAPI = createAsyncThunk(
  PortfolioActionTypes.FETCH_PORTFOLIO_API,
  async () => {
    try {
      const portfolio = await clientInvestSdk.getPortfolio();
      return portfolio.data || [];
    } catch (e: any) {
      if (e.response.status !== 401) {
        logException({
          tag: "fetchPortfolioAPI",
          exception: e,
        });
      }

      return [];
    }
  }
);

export const clearErrorAndResult = () => ({
  type: PortfolioActionTypes.CLEAR_ERROR_AND_RESULT,
});
