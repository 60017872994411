import { AxiosPromise, AxiosStatic } from "axios";
import { Entity, EntityDocumentOptions, EntityInput } from "../types/Entity";
import { BankAccount } from "../types/BankAccount";
import { FileInterface } from "../types";

export const getEntities =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (): AxiosPromise<Entity[]> =>
    await appAxios.get(`${serverURL}/entities`, {
      withCredentials: true,
    });

export const postEntity =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (entity: EntityInput): AxiosPromise<Entity> =>
    await appAxios.post(`${serverURL}/entities`, entity, {
      withCredentials: true,
    });

export const postEntityBankAccount =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (
    entityId: string,
    bankAccount: BankAccount
  ): AxiosPromise<BankAccount> =>
    await appAxios.post(
      `${serverURL}/entities/${entityId}/bank-account`,
      bankAccount,
      {
        withCredentials: true,
      }
    );

export const uploadEntityDocument =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (
    entityId: string,
    file: File,
    documentType: EntityDocumentOptions
  ): AxiosPromise<FileInterface> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentType", documentType);

    return await appAxios.post(
      `${serverURL}/entities/${entityId}/document/upload`,
      formData,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

export const deleteEntityDocument =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (entityId: string, documentId: string): AxiosPromise<void> =>
    await appAxios.get(
      `${serverURL}/entities/${entityId}/document/${documentId}/delete`,
      {
        withCredentials: true,
      }
    );
