import { createAsyncThunk } from "@reduxjs/toolkit";
import { logException } from "@helpers/logException/logException";
import { clientInvestSdk } from "@utils/clientInvestSdk";
import {
  BankAccount,
  Entity,
  EntityDocumentOptions,
  EntityInput,
  FileInterface,
} from "@ifgengineering/client-invest-sdk";

const FETCH_ENTITIES_API = "FETCH_ENTITIES_API";
const POST_ENTITY_API = "POST_ENTITY_API";
const POST_ENTITY_BANK_ACCOUNT_API = "POST_ENTITY_BANK_ACCOUNT_API";
const UPLOAD_ENTITY_DOCUMENT_API = "UPLOAD_ENTITY_DOCUMENT_API";
const DELETE_ENTITY_DOCUMENT_API = "DELETE_ENTITY_DOCUMENT_API";

export const fetchEntitiesAPI = createAsyncThunk<Entity[]>(
  FETCH_ENTITIES_API,
  async () => {
    try {
      const response = await clientInvestSdk.getEntities();

      return response.data;
    } catch (e: any) {
      if (e.response.status !== 401) {
        logException({
          tag: "fetchEntitiesAPI",
          exception: e,
        });
      }

      return [];
    }
  }
);

export const postEntityAPI = createAsyncThunk<
  Entity,
  EntityInput,
  { rejectValue: any }
>(POST_ENTITY_API, async (entity: EntityInput, { rejectWithValue }) => {
  try {
    const response = await clientInvestSdk.postEntity(entity);

    return response.data;
  } catch (e: any) {
    if (e.response.status !== 401) {
      logException({
        tag: "postEntityAPI",
        exception: e,
      });
    }

    return rejectWithValue(e);
  }
});

type uploadEntityDocumentInput = {
  entityId: string;
  file: File;
  documentType: EntityDocumentOptions;
};

type uploadEntityDocumentOutput = {
  entityId: string;
  document: FileInterface;
  documentType: EntityDocumentOptions;
};

export const uploadEntityDocumentAPI = createAsyncThunk<
  uploadEntityDocumentOutput,
  uploadEntityDocumentInput,
  { rejectValue: any }
>(
  UPLOAD_ENTITY_DOCUMENT_API,
  async (
    { entityId, file, documentType }: uploadEntityDocumentInput,
    { rejectWithValue }
  ) => {
    try {
      const document = await clientInvestSdk.uploadEntityDocument(
        entityId,
        file,
        documentType
      );

      return { entityId, document: document.data, documentType };
    } catch (e: any) {
      if (e.response.status !== 401) {
        logException({
          tag: "uploadEntityDocumentAPI",
          exception: e,
        });
      }

      return rejectWithValue(e);
    }
  }
);

type postEntityBankAccountInput = {
  entityId: string;
  bankAccount: BankAccount;
};

export const postEntityBankAccountAPI = createAsyncThunk<
  BankAccount,
  postEntityBankAccountInput,
  { rejectValue: any }
>(
  POST_ENTITY_BANK_ACCOUNT_API,
  async (
    { entityId, bankAccount }: postEntityBankAccountInput,
    { rejectWithValue }
  ) => {
    try {
      const response = await clientInvestSdk.postEntityBankAccount(
        entityId,
        bankAccount
      );

      return response.data;
    } catch (e: any) {
      if (e.response.status !== 401) {
        logException({
          tag: "postEntityBankAccountAPI",
          exception: e,
        });
      }

      return rejectWithValue(e);
    }
  }
);

type DeleteEntityDocument = {
  entityId: string;
  documentId: string;
  documentType: EntityDocumentOptions;
};

export const deleteEntityDocumentAPI = createAsyncThunk<
  DeleteEntityDocument,
  DeleteEntityDocument,
  { rejectValue: any }
>(
  DELETE_ENTITY_DOCUMENT_API,
  async (
    { entityId, documentId, documentType }: DeleteEntityDocument,
    { rejectWithValue }
  ) => {
    try {
      await clientInvestSdk.deleteEntityDocument(entityId, documentId);

      return { entityId, documentId, documentType };
    } catch (e: any) {
      if (e.response.status !== 401) {
        logException({
          tag: "deleteEntityDocumentAPI",
          exception: e,
        });
      }

      return rejectWithValue(e);
    }
  }
);
