import styled from "styled-components";
import { CardProps } from "./Card";

const calcBoxShadow = (elevation: number) => {
  switch (elevation) {
    case 0:
      return "none";
    case 1:
      return "0px 1px 6px rgb(52 111 180 / 15%)";
    case 2:
      return "0px 1px 6px 4px rgb(52 111 180 / 15%)";
    case 3:
      return "0px 1px 12px 9px rgb(52 111 180 / 15%)";
    default:
      return "none";
  }
};

export const CardContainer = styled.div<CardProps>`
  background: ${({ background }) => background};
  border-radius: ${({ borderRadius }) => borderRadius + "px"};
  padding: 24px;
  box-shadow: ${({ elevation }) => calcBoxShadow(elevation || 0)};
  transition: box-shadow 500ms;
  height: 100%;
`;
