import React, { FC } from "react";
import { navigate } from "gatsby";
import { Banner } from "@ifgengineering/component-library";

export const CompleteOnboardingBanner: FC = () => {
  return (
    <Banner
      testId="complete-onboarding-link"
      text="You need to complete your investor profile to be able to invest or view deals."
      CTAText="Complete Investor Profile"
      onClickCTA={() => navigate("/app/onboarding")}
    />
  );
};
