import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { navigate } from "gatsby";
import styled from "styled-components";
import * as Yup from "yup";
import Icon from "@icon-park/react/es/all";
import {
  Text,
  FilledButton as Button,
  Input,
  theme,
  PasswordInput,
} from "@ifgengineering/component-library";
import { Field, Formik, Form } from "formik";
import {
  Container,
  TermsLink,
  Link,
  TextDivider,
  StyledLine,
  OutlineButtonWithImage,
} from "../styled";
import ReCAPTCHA from "react-google-recaptcha";
import { ErrorContainer } from "../MagicCode/styled";
import { AuthComponentsContext } from "../../../Context";
import { SignupFieldType, SignupProps } from "./types";
import GoogleIcon from "../../../../../assets/googleIcon.png";
import AppleIcon from "../../../../../assets/appleIcon.png";
import AppleLogin from "react-apple-login";
import { toast } from "react-toastify";

const ReCaptchaContainer = styled.div`
  width: 100%;
  height: 104px;
`;

const Signup: FC<SignupProps> = ({
  onSubmit,
  title,
  errorMessage,
  loginUrl,
  signupRedirect,
  googleLogin,
  appleLogin,
}) => {
  const { useAnalytics, signupAction, recaptchaClientKey } = useContext(
    AuthComponentsContext
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const fireAnalytics = useAnalytics("signupAttempt");
  const firePageViewAnalytics = useAnalytics("pageView");
  const dispatch = useDispatch();
  const recaptchaRef = useRef() as React.MutableRefObject<ReCAPTCHA>;

  const SignupSchema = Yup.object().shape({
    password: Yup.string()
      .min(12, "Must be at least 12 characters")
      .max(128, "Please enter a password less than 128 characters")
      .required("Password is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    captchaToken: recaptchaClientKey
      ? Yup.string().required("Please verify you are not a robot")
      : Yup.string().nullable(),
  });

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({ eventName });
    };

    firePageView("all:signup_pageViewed");
  }, []);

  const defaultSubmit = async (values: {
    email: string;
    password: string;
    captchaToken: string;
  }) => {
    const response = await dispatch(
      signupAction({
        email: values.email.toLowerCase(),
        password: values.password,
        signupRedirect,
        captchaToken: values.captchaToken,
      })
    );
    (await fireAnalytics)({
      email: values.email.toLowerCase(),
      method: "password",
    });

    if (response?.payload?.response?.status === 409) {
      setError("Email already exists");
      recaptchaRef.current.reset();
    }

    if (response?.payload?.response?.status === 400) {
      recaptchaRef.current.reset();
    }
  };

  const handleSubmit = async (values: {
    email: string;
    password: string;
    captchaToken: string;
  }) => {
    const submitFn = onSubmit || defaultSubmit;
    setIsLoading(true);
    await submitFn({ ...values });
    setIsLoading(false);
  };

  const isSocialLoginEnabled = googleLogin?.enable || appleLogin?.enable;

  return (
    <Formik
      enableReinitialize
      validationSchema={SignupSchema}
      initialValues={{ email: "", password: "", captchaToken: "" }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => {
        return (
          <Form>
            <Container>
              <Text type="T18" color="SLATE800" fontFamily="archiasemibold">
                {title || "Sign Up"}
              </Text>
              {googleLogin?.enable && (
                <OutlineButtonWithImage
                  text="Continue with Google"
                  image={GoogleIcon}
                  testId="google-login-button"
                  onClick={googleLogin?.onClick}
                  type="button"
                />
              )}
              {appleLogin?.enable && (
                <AppleLogin
                  clientId={appleLogin.clientId}
                  redirectURI={appleLogin.redirectURI}
                  usePopup={true}
                  responseMode="form_post"
                  responseType="code"
                  render={({ onClick }) => (
                    <OutlineButtonWithImage
                      text="Continue with Apple"
                      image={AppleIcon}
                      testId="apple-login-button"
                      onClick={onClick}
                      type="button"
                    />
                  )}
                  callback={(data) => {
                    if ("error" in data) {
                      toast.error("Error authenticating with Apple.");
                      return;
                    }
                    appleLogin.onClick && appleLogin?.onClick(data);
                  }}
                />
              )}
              {isSocialLoginEnabled && (
                <TextDivider data-testid="txnLiUpdate">
                  <StyledLine data-testid="txnLiUpdate-line-1" />
                  <Text type="S14" color="SLATE700" testId="txnLiUpdate-update">
                    or Sign Up with Email
                  </Text>
                  <StyledLine data-testid="txnLiUpdate-line-2" />
                </TextDivider>
              )}

              <Field name="email">
                {({ field, form: { errors, touched } }: SignupFieldType) => (
                  <Input
                    {...field}
                    testId="email-input"
                    onFocus={() => setError("")}
                    errorMessage={
                      (touched.email ? errors.email : undefined) ||
                      error ||
                      errorMessage
                    }
                    heading="Email"
                  />
                )}
              </Field>
              <Field name="password">
                {({ field, form: { errors, touched } }: SignupFieldType) => (
                  <PasswordInput
                    minNumCharacters={12}
                    {...field}
                    errorMessage={
                      touched.password ? errors.password : undefined
                    }
                    onChange={(value) => setFieldValue("password", value)}
                    heading="Create Password"
                  />
                )}
              </Field>
              <Field name="captchaToken">
                {({ form: { errors, touched } }: SignupFieldType) => (
                  <ReCaptchaContainer data-testid="recaptcha-container">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={`${recaptchaClientKey}`}
                      size="normal"
                      onChange={(value) => setFieldValue("captchaToken", value)}
                    />
                    {touched.captchaToken && errors.captchaToken && (
                      <ErrorContainer>
                        <Icon
                          type="Caution"
                          size={14}
                          fill={theme.colors.ERROR800}
                          theme="filled"
                        />
                        <Text type="P14" color="ERROR800">
                          {errors.captchaToken}
                        </Text>
                      </ErrorContainer>
                    )}
                  </ReCaptchaContainer>
                )}
              </Field>
              <Button
                testId="signup-button"
                id="signup-button"
                type="submit"
                height="56px"
                text="Sign Up"
                color="darkBlue"
                isLoading={isLoading}
              />
              <Text type="P14" color="SLATE700">
                By creating an account, you are agreeing to our{" "}
                <TermsLink
                  href="https://cur8.capital/terms-conditions"
                  target="_blank"
                >
                  Terms and Conditions
                </TermsLink>
                {" and "}
                <TermsLink
                  href="https://www.islamicfinanceguru.com/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </TermsLink>
              </Text>
              <div>
                <Text type="S16" color="SLATE700">
                  Have an account?
                </Text>{" "}
                <Link onClick={() => navigate(loginUrl || "/auth/login")}>
                  Login
                </Link>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Signup;
