import React, { FC } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import {
  Text,
  OutlinedButton,
  FilledButton,
} from "@ifgengineering/component-library";
import ModalWindow, { ModalWindowProps } from "@components/ModalWindow";

export const CompleteOnboardingModal: FC<ModalWindowProps> = ({
  show,
  onClose,
}) => (
  <ModalWindow show={show} onClose={onClose}>
    <ContentContainer>
      <TextsContainer>
        <Text type="H24" color="SLATE900" fontFamily="archiaregular">
          Complete Investor Profile
        </Text>
        <Text type="P16" color="SLATE800">
          Due to our FCA regulation, you can only view and invest in deals after
          completing onboarding. If you have already completed your on-boarding,
          a 24 hour cooling off period must have past since you first signed up.
        </Text>
      </TextsContainer>
      <ButtonsContainer>
        <OutlinedButton text="Close" onClick={onClose} width="fit-content" />
        <FilledButton
          testId="complete-profile-button"
          text="Complete Investor Profile"
          color="blue"
          onClick={() => navigate("/app/onboarding")}
          width="fit-content"
        />
      </ButtonsContainer>
    </ContentContainer>
  </ModalWindow>
);

export default CompleteOnboardingModal;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;
