import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import styled from "styled-components";

export const TrustBoxWrapper = styled.div`
  paddingleft: 24px;

  @media ${MEDIA_QUERIES.TAB_LAND} {
    padding: 0 24px;
  }
`;
