const currencyEnum = ["GBP", "USD", "NGN", "EUR", "BRL"] as const;
const currencySymbolEnum = ["€", "£", "₦", "$", "R$"] as const;
export type CurrencyEnumType = (typeof currencyEnum)[number];
export type CurrencySymbolEnumType = (typeof currencySymbolEnum)[number];
type CurrencySymbolMapType = Record<CurrencyEnumType, CurrencySymbolEnumType>;

export const CURRENCY_SYMBOL_MAP: CurrencySymbolMapType = {
  BRL: "R$",
  EUR: "€",
  GBP: "£",
  NGN: "₦",
  USD: "$",
};
