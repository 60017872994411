import React, { FC } from "react";
import { navigate } from "gatsby";
import Icon from "@icon-park/react/es/all";
import { Container, TermsLink, Link, BackContainer } from "../styled";
import {
  Text,
  FilledButton as Button,
  theme,
} from "@ifgengineering/component-library";

interface SignupAppleChooseFlowProps {
  title?: string;
  signupUrl?: string;
  loginUrl?: string;
  onRegister?: VoidFunction;
  onLink: VoidFunction;
}

const SignupAppleChooseFlow: FC<SignupAppleChooseFlowProps> = ({
  title,
  signupUrl = "/app/signup",
  loginUrl = "/app/login",
  onRegister,
  onLink,
}) => {
  return (
    <Container gap={16}>
      <BackContainer onClick={() => navigate(signupUrl)}>
        <Icon
          type="ArrowLeft"
          size={18}
          theme="outline"
          fill={theme.colors.BLUE600}
        />
        <Text type="S16" color="BLUE600" fontFamily="Inter">
          Back
        </Text>
      </BackContainer>
      <Text type="T32" color="SLATE800" fontFamily="archiasemibold">
        {title || "Sign Up"}
      </Text>
      <Text type="P16" color="SLATE800">
        You’re using Apple ID to sign up. If you’re a new user, register a new
        account, or if you’re an existing user, you can choose to link your
        existing account with your Apple ID.
      </Text>
      <Button
        testId="apple-register-button"
        id="apple-register-button"
        type="submit"
        height="56px"
        text="Register a new account"
        color="darkBlue"
        onClick={onRegister}
      />
      <Button
        testId="start-apple-link-button"
        type="button"
        height="56px"
        text="Link an existing account"
        color="lightBlue"
        textColor="BLUE600"
        onClick={onLink}
        customBackgroundColors={{
          default: theme.colors.INDIGO100,
          hover: theme.colors.INDIGO500,
          active: theme.colors.INDIGO900,
          selected: theme.colors.INDIGO500,
        }}
      />
      <Text type="P14" color="SLATE700">
        By creating an account, you are agreeing to our{" "}
        <TermsLink href="https://cur8.capital/terms-conditions" target="_blank">
          Terms and Conditions
        </TermsLink>
        {" and "}
        <TermsLink
          href="https://www.islamicfinanceguru.com/privacy-policy"
          target="_blank"
        >
          Privacy Policy
        </TermsLink>
      </Text>
      <div>
        <Text type="S16" color="SLATE700">
          Have an account?
        </Text>{" "}
        <Link onClick={() => navigate(loginUrl)}>Login</Link>
      </div>
    </Container>
  );
};

export default SignupAppleChooseFlow;
