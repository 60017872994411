import React from "react";
import { StyledPageContainer } from "./styled";
import NavigationLayout from "../navigationLayout";

const PageContainer: React.FC = ({ children }) => {
  return (
    <NavigationLayout>
      <StyledPageContainer>{children}</StyledPageContainer>
    </NavigationLayout>
  );
};

export default PageContainer;
