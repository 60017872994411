import { AxiosPromise, AxiosStatic } from "axios";
import {
  MembershipCheckoutSession,
  MembershipCheckoutSessionInput,
} from "../types/Payment";

export const membershipCheckoutSession =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (
    payload: MembershipCheckoutSessionInput
  ): AxiosPromise<MembershipCheckoutSession> =>
    await appAxios.post(
      `${serverURL}/payments/membershipCheckoutSession`,
      payload,
      {
        withCredentials: true,
      }
    );
