import React from "react";
import { MEDIA_QUERIES, Text, theme } from "@ifgengineering/component-library";
import Icon from "@icon-park/react/es/all";
import { investmentOptions } from "./consts";
import { useMediaQuery } from "react-responsive";
import { Chips } from "./Chips";
import TrustBox from "../../TrustBox";
import {
  BodyWrapper,
  HeaderContainer,
  InvestInContainer,
  PromoTwoWrapper,
} from "./styled";
import { TrustBoxWrapper } from "../../TrustBox/styled";

export const PromoTwoHeader: React.FC = () => {
  return (
    <HeaderContainer>
      <Text type="T32" color="GOLD500" fontFamily="archiasemibold">
        Investing's best kept secret
      </Text>
      <Text type="P18" color="BLUE100">
        Access carefully curated institutional-grade investments instantly.
      </Text>
    </HeaderContainer>
  );
};

export const PromoTwoBody: React.FC = () => {
  const isTabPort = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });

  const investChipBgColor =
    "linear-gradient(268.87deg, #305CFF 0.17%, #1B3BB1 95.28%)";

  return (
    <BodyWrapper>
      <InvestInContainer>
        <Text type="H14" color="SLATE200">
          Invest in
        </Text>
        <Chips
          items={investmentOptions}
          bgColor={investChipBgColor}
          textColor="INDIGO100"
        />

        <div>
          <Icon type="PeopleSafe" fill={theme.colors.SUCCESS300} size={16} />
          <Text type="P14" color="SLATE300">
            All funds are vetted for sharia compliance
          </Text>
        </div>
      </InvestInContainer>
      {/*{!isTabPort && <TrustBox />}*/}
    </BodyWrapper>
  );
};

const PromoTwo: React.FC = () => {
  const isTabPort = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });

  return (
    <PromoTwoWrapper>
      <PromoTwoHeader />
      <TrustBoxWrapper>{isTabPort && <TrustBox />}</TrustBoxWrapper>
      {!isTabPort && <PromoTwoBody />}
    </PromoTwoWrapper>
  );
};

export default PromoTwo;
