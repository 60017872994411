import requests from "./requests";
import axios, { AxiosProgressEvent, AxiosPromise, AxiosStatic } from "axios";
import {
  Commitment,
  CommitmentHistoryOutput,
  CommitmentInput,
  CommitmentPreviewPayload,
  Deal,
  FileInterface,
  GetCommitmentPreviewInput,
  GetCommitmentsInput,
  GetDealsParams,
  GetQuizOutput,
  InvestmentStatementDocInput,
  Investor,
  KycProfile,
  NetPromoterScore,
  Payment,
  PaymentInput,
  PaymentTimelineActivityEvent,
  Portfolio,
  PostQuizInput,
  PostQuizOutput,
  ReturnCalculatorInput,
  ReturnCalculatorResponse,
} from "./types";
import { Entity, EntityDocumentOptions, EntityInput } from "./types/Entity";
import { BankAccount } from "./types/BankAccount";
import {
  MembershipCheckoutSession,
  MembershipCheckoutSessionInput,
} from "./types/Payment";
import {
  AccreditationQuestion,
  AccreditationRequest,
} from "./types/Accreditation";
import {
  OnboardInvestorReturn,
  UpdateOnboardingAPIPayload,
} from "./types/Onboarding";
import {
  CreateUserBankAccountInput,
  UpdateUserBankAccountInput,
  UserBankAccount,
} from "./types/UserBankAccount";
import { Liquidation, LiquidationInput } from "./types/Liquidation";
import { Withdrawal } from "./types/Withdrawal";

type APIParams = {
  serverURL: string;
  appAxios?: AxiosStatic;
};

type InitializeApiSignature = {
  deleteEntityDocument: (
    entityId: string,
    documentId: string
  ) => AxiosPromise<void>;
  deleteKYCDocument: (documentId: string) => AxiosPromise<void>;
  getAccreditationQuestionnaire: () => AxiosPromise<AccreditationQuestion[]>;
  getCommitmentHistory: (
    commitmentId: string
  ) => AxiosPromise<CommitmentHistoryOutput>;
  getCommitmentPreview: (
    params: GetCommitmentPreviewInput
  ) => Promise<CommitmentPreviewPayload>;
  getCommitments: (data?: GetCommitmentsInput) => AxiosPromise<Commitment[]>;
  getDeal: (dealId: string) => AxiosPromise<Deal>;
  getDeals: (params?: GetDealsParams) => AxiosPromise<Deal[]>;
  getEntities: () => AxiosPromise<Entity[]>;
  getInvestor: () => AxiosPromise<Investor>;
  getPaymentHistories: (
    paymentId: string
  ) => AxiosPromise<PaymentTimelineActivityEvent[]>;
  getPortfolio: () => AxiosPromise<Portfolio[]>;
  getQuiz: () => AxiosPromise<GetQuizOutput>;
  postEntity: (entity: EntityInput) => AxiosPromise<Entity>;
  postEntityBankAccount: (
    entityId: string,
    bankAccount: BankAccount
  ) => AxiosPromise<BankAccount>;
  postQuiz: (quiz: PostQuizInput[]) => AxiosPromise<PostQuizOutput>;
  onboardInvestor: (
    data: UpdateOnboardingAPIPayload
  ) => AxiosPromise<OnboardInvestorReturn>;
  reaccreditateInvestor: (
    data: AccreditationRequest
  ) => AxiosPromise<AccreditationQuestion[]>;
  uploadEntityDocument: (
    entityId: string,
    file: File,
    documentType: EntityDocumentOptions
  ) => AxiosPromise<FileInterface>;
  uploadKYCDocument: (file: File) => AxiosPromise<FileInterface>;
  postSourceOfFundDescription: (
    sourceOfFundDescription: string
  ) => AxiosPromise<KycProfile>;
  postNPS: (npsData: NetPromoterScore) => AxiosPromise<NetPromoterScore>;
  downloadInvestmentStatementDocument: (
    data: InvestmentStatementDocInput
  ) => AxiosPromise;
  downloadInvestmentStatementDocumentV2: (
    data: InvestmentStatementDocInput,
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  ) => AxiosPromise;
  membershipCheckoutSession: (
    payload: MembershipCheckoutSessionInput
  ) => AxiosPromise<MembershipCheckoutSession>;
  postCommitment: (commitment: CommitmentInput) => AxiosPromise<Commitment>;
  postPayment: (body: {
    commitmentId: string;
    paymentInput: PaymentInput;
  }) => AxiosPromise<Payment>;
  getReturnCalculator: (
    body: ReturnCalculatorInput
  ) => AxiosPromise<ReturnCalculatorResponse>;
  generateZakatStatement: () => AxiosPromise;
  getUserBankAccounts: () => AxiosPromise<UserBankAccount[]>;
  postUserBankAccount: (
    file: File,
    data: CreateUserBankAccountInput
  ) => AxiosPromise<void>;
  putUserBankAccount: (
    id: string,
    data: UpdateUserBankAccountInput,
    file?: File
  ) => AxiosPromise<void>;
  deleteUserBankAccount: (id: string) => AxiosPromise<void>;
  findLiquidationById: (liquidationId: string) => AxiosPromise<Liquidation>;
  createLiquidation: (liquidation: LiquidationInput) => AxiosPromise<void>;
  findWithdrawalById: (withdrawalId: string) => AxiosPromise<Withdrawal>;
};

const InitializeApi: (payload: APIParams) => InitializeApiSignature = ({
  serverURL,
  appAxios = axios,
}) => {
  return {
    deleteEntityDocument: requests.deleteEntityDocument(serverURL, appAxios),
    getCommitmentHistory: requests.getCommitmentHistory(serverURL, appAxios),
    deleteKYCDocument: requests.deleteKYCDocument(serverURL, appAxios),
    getAccreditationQuestionnaire: requests.getAccreditationQuestionnaire(
      serverURL,
      appAxios
    ),
    getCommitmentPreview: requests.getCommitmentPreview(serverURL, appAxios),
    getDeal: requests.getDeal(serverURL, appAxios),
    getDeals: requests.getDeals(serverURL, appAxios),
    getEntities: requests.getEntities(serverURL, appAxios),
    getInvestor: requests.getInvestor(serverURL, appAxios),
    getPaymentHistories: requests.getPaymentHistories(serverURL, appAxios),
    getPortfolio: requests.getPortfolio(serverURL, appAxios),
    getQuiz: requests.getQuiz(serverURL, appAxios),
    postCommitment: requests.postCommitment(serverURL, appAxios),
    postPayment: requests.postPayment(serverURL, appAxios),
    postEntity: requests.postEntity(serverURL, appAxios),
    postEntityBankAccount: requests.postEntityBankAccount(serverURL, appAxios),
    postQuiz: requests.postQuiz(serverURL, appAxios),
    onboardInvestor: requests.onboardInvestor(serverURL, appAxios),
    reaccreditateInvestor: requests.reaccreditateInvestor(serverURL, appAxios),
    uploadEntityDocument: requests.uploadEntityDocument(serverURL, appAxios),
    uploadKYCDocument: requests.uploadKYCDocument(serverURL, appAxios),
    postSourceOfFundDescription: requests.postSourceOfFundDescription(
      serverURL,
      appAxios
    ),
    postNPS: requests.postNPS(serverURL, appAxios),
    downloadInvestmentStatementDocument:
      requests.downloadInvestmentStatementDocument(serverURL, appAxios),
    downloadInvestmentStatementDocumentV2:
      requests.downloadInvestmentStatementDocumentV2(serverURL, appAxios),
    membershipCheckoutSession: requests.membershipCheckoutSession(
      serverURL,
      appAxios
    ),
    getCommitments: requests.getCommitments(serverURL, appAxios),
    getReturnCalculator: requests.getReturnCalculator(serverURL, appAxios),
    generateZakatStatement: requests.generateZakatStatement(
      serverURL,
      appAxios
    ),
    getUserBankAccounts: requests.getUserBankAccounts(serverURL, appAxios),
    postUserBankAccount: requests.postUserBankAccount(serverURL, appAxios),
    putUserBankAccount: requests.putUserBankAccount(serverURL, appAxios),
    deleteUserBankAccount: requests.deleteUserBankAccount(serverURL, appAxios),
    findLiquidationById: requests.findLiquidationById(serverURL, appAxios),
    createLiquidation: requests.createLiquidation(serverURL, appAxios),
    findWithdrawalById: requests.findWithdrawalById(serverURL, appAxios),
  };
};

export * from "./types";
export * from "./helpers";
export { InitializeApi };
