import { createAsyncThunk } from "@reduxjs/toolkit";
import { Deal } from "@ifgengineering/client-invest-sdk";
import { logException } from "@helpers/logException/logException";
import { clientInvestSdk } from "@utils/clientInvestSdk";

const FETCH_DEALS_API = "FETCH_DEALS_API";
const FETCH_EXPIRED_DEALS_API = "FETCH_EXPIRED_DEALS_API";

export const fetchDealsAPI = createAsyncThunk<Deal[]>(
  FETCH_DEALS_API,
  async () => {
    try {
      const response = await clientInvestSdk.getDeals({
        sortBy: "created_at:desc",
      });

      return response.data;
    } catch (e: any) {
      if (e.response.status !== 401) {
        logException({
          tag: "fetchDealsAPI",
          exception: e,
        });
      }

      return [];
    }
  }
);

export const fetchExpiredDealsAPI = createAsyncThunk<Deal[]>(
  FETCH_EXPIRED_DEALS_API,
  async () => {
    try {
      const response = await clientInvestSdk.getDeals({
        statuses: ["CLOSED", "OFFLINE"],
        pageSize: 6,
        sortBy: "created_at:desc",
      });

      return response.data;
    } catch (e: any) {
      if (e.response.status !== 401) {
        logException({
          tag: "fetchDealsAPI",
          exception: e,
        });
      }

      return [];
    }
  }
);
