import getPlatformName from "@helpers/getPlatformName";
import styled from "styled-components";

export const NavBarContainer = styled.div`
  z-index: 10;
`;

const platformName = getPlatformName();

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: ${() =>
    platformName === "iOS" ? "-webkit-fill-available" : "100vh"};
  overflow: hidden;
`;
