import { ButtonProps, FilledButton } from "@ifgengineering/component-library";
import { theme } from "@styles/theme";
import React from "react";
import styled from "styled-components";

export type FloatingMenuItemProps = ButtonProps;

export const FloatingMenuItem: React.FC<FloatingMenuItemProps> = ({
  height = "54px",
  text,
  textColor = "SLATE900",
  textSize = "S16",
  icon,
  iconColor = "SLATE700",
  onClick,
  ...props
}): JSX.Element => {
  return (
    <FilledButton
      color="white"
      text={text}
      textSize={textSize}
      textColor={textColor}
      icon={icon}
      iconColor={iconColor}
      iconSize={14}
      onClick={onClick}
      height={height}
      customBackgroundColors={{
        hover: theme.colors.SLATE100,
        default: theme.colors.WHITE,
      }}
      borderRadius="none"
      justifyContent="left"
      {...props}
    />
  );
};

export interface FloatingMenuProps {
  collectionOfItems: Array<Array<FloatingMenuItemProps>>;
}

const FloatingMenu: React.FC<FloatingMenuProps> = ({
  collectionOfItems,
}): JSX.Element => (
  <FloatingMenuContainer>
    {collectionOfItems.map((items, groupIdx) => (
      <MenuGroupContainer key={"group-" + groupIdx}>
        {items.map((item, itemIdx) => (
          <FloatingMenuItem
            key={`item-${itemIdx}-group-${groupIdx}`}
            data-testid={item.text}
            {...item}
          />
        ))}
      </MenuGroupContainer>
    ))}
  </FloatingMenuContainer>
);

export default FloatingMenu;

const FloatingMenuContainer = styled.div`
  width: 100%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 8px 8px;
  overflow: hidden;
`;

const MenuGroupContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  border-top: 0.5px solid ${theme.colors.SLATE200};
`;
