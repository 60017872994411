import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  DEFAULT_REMOTE_CONFIG_PARAMETERS,
  TRemoteConfig,
} from "./remoteConfiguration.reducer";
import { logException } from "../../helpers/logException/logException";
import initFirebase from "../../utils/initFirebase";

export const fetchRemoteConfigThunk = createAsyncThunk<TRemoteConfig, void>(
  "REMOTE_CONFIG:FETCH_CONFIG",
  async (_, { rejectWithValue }) => {
    try {
      const remoteConfig = await initFirebase();
      if (!remoteConfig) return DEFAULT_REMOTE_CONFIG_PARAMETERS;

      return remoteConfig;
    } catch (e: any) {
      logException({
        message: "Error fetching remote config",
        exception: e,
      });
      return rejectWithValue(e.message);
    }
  }
);
