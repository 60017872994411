import { Investor } from "@ifgengineering/client-invest-sdk";
import { signInAsync, signOutAsync } from "../auth/auth.actions";
import {
  getInvestorAPI,
  onboardInvestorProfileAPI,
  updateBankDetailsAPI,
  updateInvestorAccountAction,
  updateInvestorProfileAPI,
} from "./investor.actions";

export type InvestorState = {
  neverLoaded: boolean;
  loading: boolean;
  data?: Partial<Investor>;
};

export const INITIAL_STATE: InvestorState = {
  neverLoaded: true,
  loading: false,
};

const investorReducer = (state = INITIAL_STATE, action: any): InvestorState => {
  switch (action.type) {
    case signInAsync.fulfilled.type:
    case signOutAsync.fulfilled.type:
      return {
        ...INITIAL_STATE,
      };
    case updateInvestorProfileAPI.pending.type:
    case onboardInvestorProfileAPI.pending.type:
    case updateInvestorAccountAction.pending.type:
    case updateBankDetailsAPI.pending.type:
    case getInvestorAPI.pending.type:
      return {
        ...state,
        loading: true,
      };
    case updateBankDetailsAPI.rejected.type:
      return {
        ...state,
        loading: false,
      };
    case updateInvestorProfileAPI.fulfilled.type:
    case onboardInvestorProfileAPI.fulfilled.type:
      return {
        loading: false,
        neverLoaded: false,
        data: action.payload,
      };
    case updateBankDetailsAPI.fulfilled.type:
      return {
        loading: false,
        neverLoaded: false,
        data: {
          ...state.data,
          bankDetails: action.payload,
        },
      };
    case updateInvestorAccountAction.fulfilled.type:
      return {
        loading: false,
        neverLoaded: false,
        data: action.payload,
      };
    case getInvestorAPI.fulfilled.type:
      return {
        loading: false,
        neverLoaded: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default investorReducer;
