import React, { FC, useEffect, useState, useContext } from "react";
import { Formik } from "formik";
import { InputsContainer, TimerContainer, TitleSpacer } from "./styled";
import MagicCodeInput from "./MagicCodeInput";
import {
  FilledButton as Button,
  Text,
  theme,
} from "@ifgengineering/component-library";
import axios from "axios";
import { toast } from "react-toastify";
import { BackContainer, Container } from "../styled";
import Icon from "@icon-park/react/es/all";
import { format } from "date-fns";
import { magicCodeSchema } from "./validationSchemas";
import { navigate } from "gatsby";
import useInterval from "./useInterval";
import { AuthComponentsContext } from "../../../Context";
import { COUNTDOWN_TIME_IN_SECONDS } from "../../../../consts";
interface MagicCodeFormProps {
  loginRedirect: string;
  email: string;
  handleBackButton: () => void;
}

const COUNT_TIME = COUNTDOWN_TIME_IN_SECONDS * 1000;
const DELAY = 1000;

export const MagicCodeForm: FC<MagicCodeFormProps> = ({
  loginRedirect,
  handleBackButton,
  email,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showResentOtpMessage, setShowResentOtpMessage] = useState(false);
  const [allowSendCode, setAllowSendCode] = useState(false);
  const [formattedTime, setFormattedTime] = useState("01:00");
  const [count, setCount] = useState<number>(COUNT_TIME);
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const { useAnalytics, passwordlessVerify, passwordlessStart, logException } =
    useContext(AuthComponentsContext);

  useInterval(
    () => {
      setCount(count - 1000);
    },
    isPlaying ? DELAY : null
  );

  useEffect(() => {
    setPlaying(true);
  }, []);

  useEffect(() => {
    setFormattedTime(format(new Date(count), "mm:ss"));
    if (count <= 0) {
      setPlaying(false);
      setAllowSendCode(true);
    }
  }, [count]);

  const fireAnalytics = useAnalytics("login");

  const authenticateWithMagicCode = async (otp: string) => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      (await fireAnalytics)({
        email: email.toLowerCase(),
        method: "passwordless",
      });
      await passwordlessVerify(email, otp);

      navigate(loginRedirect);
    } catch (e: any) {
      setIsLoading(false);
      if (axios.isAxiosError(e) && e.response) {
        if (e.response?.status === 401) {
          setErrorMessage("Wrong code. Please try again");
          return;
        }
      }

      toast.error("Something went wrong. Try again or contact support");

      logException({
        email,
        tag: "passwordlessVerify",
        exception: e,
      });

      throw e;
    }
  };

  const resendMagicCode = async () => {
    try {
      if (allowSendCode) {
        await passwordlessStart(email);
        fadeResentOtpMessage();
      }
    } catch (e: any) {
      if (e.response.status !== 404) {
        logException({
          email,
          tag: "passwordlessStart",
          exception: e,
        });
      }
    }
  };

  const back = () => {
    handleBackButton();
  };

  const fadeResentOtpMessage = () => {
    setShowResentOtpMessage(true);

    setTimeout(() => {
      setCount(COUNT_TIME);
      setPlaying(true);
      setAllowSendCode(false);
      setShowResentOtpMessage(false);
    }, 3000);
  };

  return (
    <div data-testid="otp">
      <BackContainer onClick={back}>
        <Icon
          type="ArrowLeft"
          size={18}
          theme="outline"
          fill={theme.colors.BLUE600}
        />
        <Text type="S16" color="BLUE600" fontFamily="Inter">
          Back
        </Text>
      </BackContainer>
      <TitleSpacer>
        <Text type="T48" color="SLATE800" fontFamily="archiasemibold">
          Check your email for Magic code
        </Text>
      </TitleSpacer>
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={magicCodeSchema}
        onSubmit={({ otp }) => {
          authenticateWithMagicCode(otp);
        }}
      >
        {({
          errors,
          touched,
          submitForm,
          isValid,
          isSubmitting,
          values,
          setSubmitting,
        }) => {
          useEffect(() => {
            if (
              !isSubmitting &&
              isValid &&
              !!values.otp &&
              values.otp.length === 6
            ) {
              setSubmitting(true);
              submitForm();
            }
          }, [isSubmitting, isValid, values.otp]);

          const formikErrorMessage = touched.otp ? errors.otp : undefined;
          const message = errorMessage || formikErrorMessage;

          return (
            <>
              <Text type="S16" color="SLATE800" fontFamily="Inter">
                Magic Code
              </Text>
              <InputsContainer>
                <MagicCodeInput
                  name="otp"
                  numInputs={6}
                  isDisabled={isLoading}
                  hasErrored={Boolean(message)}
                  errorMessage={message}
                />
              </InputsContainer>
              <Container gap={16}>
                <Text type="P16" color="SLATE800">
                  We've sent a code to the following email:{" "}
                  <Text
                    type="H16"
                    color="SLATE800"
                    as="a"
                    href={`mailto:${email}`}
                  >
                    {email}
                  </Text>
                </Text>
                <Button
                  testId="login-button"
                  type="submit"
                  height="56px"
                  text="Login"
                  color="darkBlue"
                  isLoading={isLoading}
                  disabled={!isValid || isLoading}
                  onClick={submitForm}
                />
              </Container>

              <div>
                <br />
                {showResentOtpMessage ? (
                  <Text type="S16" color="SLATE800" fontFamily="Inter">
                    Magic Code sent!
                  </Text>
                ) : (
                  <>
                    <TimerContainer>
                      <Text type="S16" color="SLATE600" fontFamily="Inter">
                        {formattedTime}
                      </Text>
                    </TimerContainer>
                    <Text
                      type="S16"
                      color={allowSendCode ? "BLUE600" : "SLATE450"}
                      fontFamily="Inter"
                      style={{ cursor: allowSendCode ? "pointer" : "" }}
                      onClick={resendMagicCode}
                    >
                      Resend Magic Code
                    </Text>
                  </>
                )}
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default MagicCodeForm;
