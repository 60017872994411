import { CURRENCY_SYMBOL_MAP, CurrencyEnumType } from "../types";
import { moneyfy } from "./moneyfy";

export const initializeUseCurrency = (
  currency: CurrencyEnumType = "GBP",
  config?: { noDecimals?: boolean }
) => {
  const currencySymbol = CURRENCY_SYMBOL_MAP[currency];
  return {
    toCurrency: moneyfy(currency, config),
    currencySymbol,
    currency,
  };
};

export default initializeUseCurrency;
