import React from "react";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import MobileNavbar from "./components/MobileNavbar";
import DesktopNavbar from "./components/DesktopNavbar";
import { MenuTemplateItem, WealthMainPageType } from "./types";

type NavbarProps = {
  children: JSX.Element;
  hideTopBarDesktop?: boolean;
  hideTopBarMobile?: boolean;
  isOpen?: boolean;
  logoutCallback: () => void;
  mobileInnerNav?: () => JSX.Element | null;
  page: string;
  template: MenuTemplateItem[];
  toggleMenu?: () => void;
  topbarButtons?: JSX.Element | null;
  modalContext?: React.Context<{
    show: boolean;
    toggleModal: () => void;
  }>;
};

const NavBar: React.FC<NavbarProps> = ({
  children,
  hideTopBarDesktop,
  hideTopBarMobile,
  isOpen,
  logoutCallback,
  mobileInnerNav,
  page,
  template,
  toggleMenu,
  topbarButtons,
  modalContext,
}) => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });

  if (isMobile) {
    return (
      <MobileNavbar
        template={template}
        isOpen={isOpen}
        toggleMenu={toggleMenu}
        page={page as WealthMainPageType}
        hideTopBar={hideTopBarMobile}
        logoutCallback={logoutCallback}
        mobileInnerNav={mobileInnerNav}
        modalContext={modalContext}
      >
        {children}
      </MobileNavbar>
    );
  }

  return (
    <DesktopNavbar
      template={template}
      logoutCallback={logoutCallback}
      hideTopBar={hideTopBarDesktop}
      page={page as WealthMainPageType}
      topbarButtons={topbarButtons}
    >
      {children}
    </DesktopNavbar>
  );
};

export default NavBar;
