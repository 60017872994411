import Icon from "@icon-park/react/es/all";
import React, { useContext } from "react";
import { Text } from "@ifgengineering/component-library";
import { capitalizeFirstLetter } from "../utils";
import { MobileFilterContainer, TopBarContainer } from "./styled";

type TopBarProps = {
  children?: JSX.Element;
  isMobileNav?: boolean;
  page: string;
  modalContext?: React.Context<{
    show: boolean;
    toggleModal: () => void;
  }>;
};

const TopBar: React.FC<TopBarProps> = ({
  isMobileNav,
  children,
  page = "",
  modalContext,
}) => {
  const context = modalContext ? useContext(modalContext) : undefined;

  return (
    <TopBarContainer isMobileNav={isMobileNav}>
      {isMobileNav ? (
        <>
          <div data-testid="nav-top-bar">
            <Text type="T24" color="WHITE">
              {capitalizeFirstLetter(page)}
            </Text>
          </div>
          {page === "Compare" && (
            <MobileFilterContainer onClick={context?.toggleModal}>
              <Text type="H14" color="WHITE">
                Filters
              </Text>
              <Icon theme="filled" fill="white" size={20} type="Filter" />
            </MobileFilterContainer>
          )}
        </>
      ) : (
        <Text type="T18" color="WHITE">
          {capitalizeFirstLetter(page)}
        </Text>
      )}
      {children}
    </TopBarContainer>
  );
};

export default TopBar;
