import React, { FC } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Logo,
  Layout,
  ImageWrapper,
  PoweredBy,
  WidgetContainer,
  Container,
  LayoutB,
  ContentWrapper,
  WidgetPilotWrapper,
} from "./styled";
import cur8White from "../../../assets/cur8-white.png";
import poweredByIfgLogo from "../../../assets/powered-by-ifg.png";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";

export interface LayoutShellProps {
  children?: React.ReactNode;
  Banner?: () => JSX.Element;
  Promo: () => JSX.Element;
  MobileFooter?: () => JSX.Element;
  Logo?: () => JSX.Element;
  backgroundColor: string;
  isLayoutBEnabled?: boolean;
  showHeader?: boolean;
  showTrustBox?: boolean;
  isCharity?: boolean;
}

const DefaultLogo = () => <Logo src={cur8White} />;

const LayoutShell: FC<LayoutShellProps> = ({
  Banner,
  Promo,
  MobileFooter,
  Logo = DefaultLogo,
  children,
  backgroundColor,
  isLayoutBEnabled,
  isCharity,
  showHeader,
}) => {
  const isTabPort = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });

  const NewHeader = () => (
    <ImageWrapper>
      <Logo />
      <PoweredBy src={poweredByIfgLogo} />
    </ImageWrapper>
  );

  if (isLayoutBEnabled && !isCharity)
    return (
      <LayoutB backgroundColor={backgroundColor}>
        <NewHeader />
        <Container>
          <WidgetContainer>{children}</WidgetContainer>
        </Container>
      </LayoutB>
    );

  return (
    <Layout backgroundColor={backgroundColor}>
      {Banner && <Banner />}
      {showHeader && <NewHeader />}
      <ContentWrapper>
        <Promo />
        <WidgetContainer>{children}</WidgetContainer>
      </ContentWrapper>
      {isTabPort && MobileFooter && <MobileFooter />}
    </Layout>
  );
};

export default LayoutShell;
