import React, { useContext, useEffect } from "react";
import Text from "../Text";
import { StyledTab, StyledTabTextWrapper } from "./styled";
import { TabContext } from "./TabContext.component";

interface TabProps {
  value: string;
  label: string;
  onClick?: (value: string) => void;
}

export const Tab = ({
  value,
  label,
  onClick,
  ...props
}: TabProps): JSX.Element => {
  const { selectTab, registerTab, theme, selectedTab, mobile } =
    useContext(TabContext);
  const { highlightColor, textColor, backgroundColor } = theme;

  useEffect(() => {
    registerTab(value);
  }, []);

  const thisTabIsSelected = selectedTab === value;
  const color = thisTabIsSelected ? highlightColor : textColor;
  const tabTextType = mobile ? "P16" : "S20";

  return (
    <StyledTab
      {...props}
      backgroundColor={backgroundColor}
      color={color}
      onClick={() => {
        selectTab(value);
        if (onClick) {
          onClick(value);
        }
      }}
      selected={thisTabIsSelected}
    >
      <StyledTabTextWrapper mobile={mobile}>
        <Text type={tabTextType} color={color} data-testid="tab-label">
          {label}
        </Text>
      </StyledTabTextWrapper>
    </StyledTab>
  );
};
export default Tab;
