import { AxiosPromise, AxiosStatic } from "axios";
import {
  CreateUserBankAccountInput,
  UpdateUserBankAccountInput,
  UserBankAccount,
} from "../types/UserBankAccount";

export const getUserBankAccounts =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (): AxiosPromise<UserBankAccount[]> =>
    await appAxios.get(`${serverURL}/user-bank-accounts`, {
      withCredentials: true,
    });

export const postUserBankAccount =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (file: File, data: CreateUserBankAccountInput): AxiosPromise<void> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("accountType", data.accountType);
    formData.append("bankName", data.bankName);
    formData.append("accountName", data.accountName);
    formData.append("accountNumber", data.accountNumber);
    formData.append("bankCountry", data.bankCountry);
    if (data.sortCode) formData.append("sortCode", data.sortCode);
    if (data.accountNickname)
      formData.append("accountNickname", data.accountNickname);
    if (data.swift) formData.append("swift", data.swift);
    if (data.iban) formData.append("iban", data.iban);

    if (data.entityId) formData.append("entityId", data.entityId);
    if (data.routingNumber)
      formData.append("routingNumber", data.routingNumber);
    if (data.purposeCode) formData.append("purposeCode", data.purposeCode);

    return await appAxios.post(`${serverURL}/user-bank-accounts`, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

export const putUserBankAccount =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (
    id: string,
    data: UpdateUserBankAccountInput,
    file?: File
  ): AxiosPromise<void> => {
    const formData = new FormData();

    if (file) formData.append("file", file);
    if (data.accountType) formData.append("accountType", data.accountType);
    if (data.bankName) formData.append("bankName", data.bankName);
    if (data.accountName) formData.append("accountName", data.accountName);
    if (data.accountNumber)
      formData.append("accountNumber", data.accountNumber);
    if (data.bankCountry) formData.append("bankCountry", data.bankCountry);
    if (data.sortCode) formData.append("sortCode", data.sortCode);
    if (data.accountNickname)
      formData.append("accountNickname", data.accountNickname);
    if (data.swift) formData.append("swift", data.swift);
    if (data.iban) formData.append("iban", data.iban);
    if (data.entityId) formData.append("entityId", data.entityId);
    if (data.routingNumber)
      formData.append("routingNumber", data.routingNumber);
    if (data.purposeCode) formData.append("purposeCode", data.purposeCode);

    return await appAxios.put(
      `${serverURL}/user-bank-accounts/${id}`,
      formData,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

export const deleteUserBankAccount =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (id: string): AxiosPromise<void> =>
    await appAxios.delete(`${serverURL}/user-bank-accounts/${id}`, {
      withCredentials: true,
    });
