import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CUR8_SERVER } from "gatsby-env-variables";
import { replaceOther } from "../../utils/checkbox/checkbox";
import { InvestorInput, UpdateInvestorAccountPayload } from "./types";
import {
  BankDetails,
  Investor,
  UpdateOnboardingAPIPayload,
  OnboardInvestorReturn,
} from "@ifgengineering/client-invest-sdk";
import { logException } from "@helpers/logException/logException";
import { clientInvestSdk } from "@utils/clientInvestSdk";

const FETCH_INVESTOR_API = "FETCH_INVESTOR_API";

const UPDATE_INVESTOR_API = "UPDATE_INVESTOR_API";

const ONBOARD_INVESTOR_API = "ONBOARD_INVESTOR_API";

const UPDATE_INVESTOR_PROFILE_API = "UPDATE_INVESTOR_PROFILE_API";

const UPDATE_INVESTOR_BANK_DETAILS_API = "UPDATE_INVESTOR_BANK_DETAILS_API";

export const updateInvestorProfileAPI = createAsyncThunk<
  Investor,
  UpdateOnboardingAPIPayload
>(UPDATE_INVESTOR_PROFILE_API, async (payload): Promise<Investor> => {
  const result = await updateInvestor(payload);

  return result.data;
});

export const onboardInvestorProfileAPI = createAsyncThunk<
  Investor,
  UpdateOnboardingAPIPayload
>(ONBOARD_INVESTOR_API, async (payload): Promise<Investor> => {
  const result = await onboardInvestor(payload);

  return result.data.investor;
});

export const updateBankDetailsAPI = createAsyncThunk<Investor, BankDetails>(
  UPDATE_INVESTOR_BANK_DETAILS_API,
  async (payload): Promise<Investor> => {
    const result = await updateBankDetails(payload);

    return result.data;
  }
);

export const updateInvestorAccountAction = createAsyncThunk(
  UPDATE_INVESTOR_API,
  async (data: UpdateInvestorAccountPayload, { rejectWithValue }) => {
    try {
      const investor = await updateInvestorAccount(data);

      return investor;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getInvestorAPI = createAsyncThunk(
  FETCH_INVESTOR_API,
  async (_, { rejectWithValue }) => {
    try {
      const result = await clientInvestSdk.getInvestor();

      return result.data;
    } catch (e: any) {
      if (e.response.status !== 401) {
        logException({
          tag: "getInvestorAPI",
          exception: e,
        });
      }

      return rejectWithValue(e);
    }
  }
);

export const updateInvestorAccount = async ({
  firstName,
  middleName,
  lastName,
  mobileNumber,
  dob,
  gender,
  addressLine1,
  buildingNameOrNumber,
  addressLine2,
  city,
  region,
  country,
  postcode,
  nationality,
  isUkTaxResident,
  profession,
  funds,
  fundsOther,
}: UpdateInvestorAccountPayload): Promise<Investor> => {
  const allFunds = replaceOther(funds, fundsOther);

  const data: InvestorInput = {
    firstName,
    middleName,
    lastName,
    mobileNumber,
    dob,
    gender,
    nationality,
    isUkTaxResident,
    address: {
      buildingNameOrNumber: "",
      addressLine1: "",
      city: "",
      region: "",
      country: "",
      postcode: "",
    },
    profession,
    funds: allFunds,
  };

  if (buildingNameOrNumber) {
    data.address.buildingNameOrNumber = buildingNameOrNumber;
  }

  if (addressLine1) {
    data.address.addressLine1 = addressLine1;
  }

  if (addressLine2) {
    data.address.addressLine2 = addressLine2;
  }

  if (city) {
    data.address.city = city;
  }

  if (region) {
    data.address.region = region;
  }

  if (country) {
    data.address.country = country;
  }

  if (postcode) {
    data.address.postcode = postcode;
  }

  const result = await axios.put(`${CUR8_SERVER}/investor`, data, {
    withCredentials: true,
  });

  return result.data as Investor;
};

export const onboardInvestor = async (
  data: UpdateOnboardingAPIPayload
): Promise<AxiosResponse<OnboardInvestorReturn>> => {
  return clientInvestSdk.onboardInvestor(data);
};

export const updateBankDetails = async (
  data: BankDetails
): Promise<AxiosResponse<Investor>> => {
  return axios.post(`${CUR8_SERVER}/investor/bank-details`, data, {
    withCredentials: true,
  });
};

export const updateInvestor = async (
  data: UpdateOnboardingAPIPayload
): Promise<AxiosResponse<Investor>> => {
  return axios.put(`${CUR8_SERVER}/investor`, data, {
    withCredentials: true,
  });
};
