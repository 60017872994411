import { Commitment } from "@ifgengineering/client-invest-sdk";
import { signInAsync, signOutAsync } from "../auth/auth.actions";
import {
  createOrUpdateCommitmentAsync,
  fetchCommitmentsAPI,
  fetchCommitmentsByDealId,
} from "@state/commitment/commitment.actions";

export interface CommitmentState {
  neverLoaded: boolean;
  loading: boolean;
  data: Commitment[];
}

export const INITIAL_STATE: CommitmentState = {
  neverLoaded: true,
  loading: false,
  data: [],
};

const commitmentReducer = (
  state = INITIAL_STATE,
  action: any
): CommitmentState => {
  switch (action.type) {
    case signInAsync.fulfilled.type:
    case signOutAsync.fulfilled.type:
      return {
        ...INITIAL_STATE,
      };
    case createOrUpdateCommitmentAsync.pending.type:
    case fetchCommitmentsAPI.pending.type:
    case fetchCommitmentsByDealId.pending.type:
      return {
        ...state,
        loading: true,
      };
    case createOrUpdateCommitmentAsync.rejected.type:
    case fetchCommitmentsAPI.rejected.type:
    case fetchCommitmentsByDealId.rejected.type:
      return {
        ...state,
        loading: false,
      };
    case fetchCommitmentsAPI.fulfilled.type:
      return {
        ...state,
        neverLoaded: false,
        loading: false,
        data: action.payload,
      };
    case fetchCommitmentsByDealId.fulfilled.type: {
      const data = updateCommitmentsStore(state.data, action.payload);
      return {
        ...state,
        loading: false,
        data,
      };
    }
    case createOrUpdateCommitmentAsync.fulfilled.type: {
      const idx = state.data.findIndex((item) => item.id === action.payload.id);
      if (idx < 0) {
        return {
          ...state,
          neverLoaded: false,
          loading: false,
          data: [...state.data, action.payload],
        };
      }
      const newArr = [...state.data];
      newArr.splice(idx, 1, action.payload);
      return {
        ...state,
        neverLoaded: false,
        loading: false,
        data: [...newArr],
      };
    }
    default:
      return state;
  }
};

export default commitmentReducer;

const updateCommitmentsStore = (
  commitmentsStore: Commitment[],
  newlyFetchedCommitments: Commitment[]
): Commitment[] => {
  const updatedCommitments = [...commitmentsStore];
  newlyFetchedCommitments.forEach((newCommitment) => {
    const idx = updatedCommitments.findIndex(
      (commitment) => commitment.id === newCommitment.id
    );
    if (idx < 0) {
      updatedCommitments.push(newCommitment);
    } else {
      updatedCommitments.splice(idx, 1, newCommitment);
    }
  });
  return updatedCommitments;
};
