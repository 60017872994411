import { AxiosPromise, AxiosStatic } from "axios";
import { Liquidation, LiquidationInput } from "../types/Liquidation";

export const findLiquidationById =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (liquidationId: string): AxiosPromise<Liquidation> =>
    await appAxios.get(`${serverURL}/liquidations/${liquidationId}`, {
      withCredentials: true,
    });

export const createLiquidation =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (liquidation: LiquidationInput): AxiosPromise<void> =>
    await appAxios.post(`${serverURL}/liquidations`, liquidation, {
      withCredentials: true,
    });
