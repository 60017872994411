import React, { FC, useContext } from "react";
import {
  StyledMobileTabsContainerOverlay,
  StyledTabsContainer,
  StyledTabsBottomBorder,
  StyledTabsScroll,
} from "./styled";
import { TabContext } from "./TabContext.component";

export const Tabs: FC = ({ children }) => {
  const { theme, mobile } = useContext(TabContext);
  const { tabsBottomBorder } = theme;

  return (
    <StyledTabsContainer>
      {mobile && (
        <StyledMobileTabsContainerOverlay color={theme.backgroundColor} />
      )}
      <StyledTabsScroll tabTheme={theme} mobile={mobile}>
        {children}
      </StyledTabsScroll>
      {tabsBottomBorder?.enabled && (
        <StyledTabsBottomBorder borderColor={tabsBottomBorder.color} />
      )}
    </StyledTabsContainer>
  );
};

export default Tabs;
