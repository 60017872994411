import { FileInterface } from "./Documents";

export const currencyEnum = ["GBP", "USD"] as const;
export const currencySymbolEnum = ["£", "$"] as const;
export type CurrencyEnumType = (typeof currencyEnum)[number];
export type CurrencySymbolEnumType = (typeof currencySymbolEnum)[number];
export type CurrencySymbolMapType = Record<
  CurrencyEnumType,
  CurrencySymbolEnumType
>;

export const CURRENCY_SYMBOL_MAP: CurrencySymbolMapType = {
  GBP: "£",
  USD: "$",
};

export interface Investable {
  id: string;
  name: string;
  description: string | null;
  displayImage?: FileInterface | null;
  backgroundImage?: FileInterface | null;
  website: string | null;
  twitter: string | null;
  linkedIn: string | null;
  companiesHouseUrl: string | null;
  hasRegulatoryWarning: boolean;
  currency: CurrencyEnumType;
  created_at: string;
  updated_at?: string | null;
  metadata?: {
    assetClass?: string;
    [x: string]: unknown;
  };
}
