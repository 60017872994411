import React, { FC, useState, useContext } from "react";
import { navigate } from "gatsby";
import {
  FilledButton,
  PasswordInput,
  Text,
} from "@ifgengineering/component-library";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import * as queryString from "query-string";
import { useLocation } from "@reach/router";
import { Container } from "../styled";
import { AuthComponentsContext } from "../../../Context";

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
  form: {
    errors: {
      password: string;
      passwordConfirm: string;
    };
    touched: {
      password: string;
      passwordConfirm: string;
    };
  };
};

export const Schema = Yup.object().shape({
  password: Yup.string()
    .min(12, "Must be at least 12 characters")
    .max(64, "Please enter a password less than 64 characters")
    .required("Password is required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords do not match")
    .required("Password confirmation is required"),
});

const ResetSuccessful = () => (
  <>
    <Text type={"T48"} color="SLATE800" fontFamily="archialight">
      Password reset successful
    </Text>
    <FilledButton
      type="submit"
      height="56px"
      text="Proceed to Login"
      color="blue"
      onClick={() => navigate("/auth/login/")}
    />
  </>
);

interface PasswordResetProps {
  onSubmit?: (password: string) => void;
}

const PasswordReset: FC<PasswordResetProps> = ({ onSubmit }) => {
  const { useAnalytics, resetPassword } = useContext(AuthComponentsContext);
  const location = useLocation();
  const [error, setError] = useState("");
  const { email, token } = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const fireForgotPasswordAnalytics = useAnalytics(
    "forogtPasswordSetPasswordClick"
  );

  const handleSubmit = async (values: { password: string }) => {
    (await fireForgotPasswordAnalytics)();
    setIsLoading(true);
    setError("");
    try {
      if (onSubmit && !isLoading) {
        await onSubmit(values.password);
      } else {
        await resetPassword({
          email: email as string,
          token: token as string,
          newPassword: values.password,
        });
      }
      setIsPasswordReset(true);
    } catch (err) {
      setError("Unable to reset password, please try again.");
      console.error(err);
    }
    setIsLoading(false);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={Schema}
      initialValues={{ password: "", passwordConfirm: "" }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => {
        return (
          <Form>
            <Container>
              {isPasswordReset ? (
                <ResetSuccessful />
              ) : (
                <>
                  <Text type="T32" color="SLATE800" fontFamily="archiasemibold">
                    Password reset
                  </Text>
                  <Field name="password">
                    {({ field, form: { errors, touched } }: FieldType) => (
                      <PasswordInput
                        {...field}
                        errorMessage={
                          touched.password ? errors.password : undefined
                        }
                        onChange={(value) => setFieldValue("password", value)}
                        heading="New Password"
                        hideBar
                      />
                    )}
                  </Field>
                  <Field name="passwordConfirm">
                    {({ field, form: { errors, touched } }: FieldType) => (
                      <PasswordInput
                        {...field}
                        errorMessage={
                          touched.passwordConfirm
                            ? errors.passwordConfirm
                            : undefined
                        }
                        onChange={(value) =>
                          setFieldValue("passwordConfirm", value)
                        }
                        heading="Confirm New Password"
                        hideBar
                      />
                    )}
                  </Field>
                  {error && (
                    <Text type={"P16"} color={"ERROR500"}>
                      {error}
                    </Text>
                  )}
                  <FilledButton
                    type="submit"
                    height="56px"
                    text="Set Password"
                    color="darkBlue"
                    disabled={isLoading}
                    isLoading={isLoading}
                  />
                </>
              )}
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PasswordReset;
