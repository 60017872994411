import styled from "styled-components";
import { theme, MEDIA_QUERIES } from "@ifgengineering/component-library";

export const Logo = styled.img`
  width: 80px;
`;
export const PoweredBy = styled.img`
  width: 99px;
  height: 24px;
`;

export const ImageWrapper = styled.div`
  align-self: flex-start;
  width: 100%;
  display: flex;
  padding: 40px 64px;
  justify-content: space-between;
  align-items: center;

  @media ${MEDIA_QUERIES.BIG_DESKTOP} {
    align-items: baseline;
    padding: 65px 99px 0;
  }
  @media ${MEDIA_QUERIES.TAB_LAND} {
    align-items: baseline;
    padding: 32px 24px;
  }
`;
export const LayoutB = styled.div<{ backgroundColor: string }>`
  background: ${({ backgroundColor }) => backgroundColor};
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${MEDIA_QUERIES.TAB_PORT} {
    justify-content: flex-start;
    gap: 48px;
  }
`;

export const Layout = styled.div<{ backgroundColor: string }>`
  background: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  flex-direction: row;
  margin: auto;
  padding: 0 208px 0 160px;

  @media ${MEDIA_QUERIES.TAB_LAND} {
    padding: 0 50px;
  }

  @media ${MEDIA_QUERIES.TAB_PORT} {
    flex-direction: column;
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media ${MEDIA_QUERIES.TAB_PORT} {
    justify-content: flex-start;
  }
`;

export const WidgetPilotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    width: 100%;
  }
`;

export const WidgetContainer = styled.div`
  background-color: ${theme.colors.WHITE};
  border-radius: 8px;
  width: 410px;
  min-height: 200px;
  max-height: 100vh;
  box-shadow: 0 4px 80px rgba(0, 0, 0, 0.25);
  padding: 32px 40px;
  overflow: scroll;

  @media ${MEDIA_QUERIES.TAB_PORT} {
    width: 100%;
    padding: 32px 24px;
  }
`;
