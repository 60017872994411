import React, { FC } from "react";
import styled from "styled-components";
import { Text, theme } from "@ifgengineering/component-library";

const StyledFoter = styled.div`
  background: ${theme.colors["SLATE100"]};
  padding: 24px 104px;
`;

const Footer: FC = () => {
  return (
    <StyledFoter>
      <Text type="P16" color="SLATE700">
        Cur8 Capital is a trading name of IFG.VC Limited, which is a subsidiary
        of Islamicfinanceguru Limited. IFG.VC Limited is authorised and
        regulated by the FCA (No. 943736). Nothing on this page should be
        construed as financial or tax advice. Remember, with investments your
        capital is at risk. Past performance is not necessarily an accurate
        indicator of future returns. © IFG.VC Limited. All rights reserved
      </Text>
    </StyledFoter>
  );
};

export default Footer;
