import styled from "styled-components";
import { theme } from "@ifgengineering/component-library";
import React from "react";

export const Container = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ gap }) => (gap ? `${gap}px` : "8px")};
`;

export const TermsLink = styled.a`
  text-decoration: underline;
`;

export const Link = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.colors.BLUE600};
  cursor: pointer;
`;

export const ForgotPassword = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.BLUE600};
  cursor: pointer;
  text-align: right;
`;

export const BackContainer = styled.a`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;

export const StyledLine = styled.hr`
  width: 100%;
  height: 0;
  border-top: 1px solid ${theme.colors.SLATE300};
`;

export const TextDivider = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 0 9.5px;
  text-align: center;
`;

export const OutlineButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375em 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${theme.colors.WHITE};
  color: ${theme.colors.SLATE700};
  font-size: 14px;
  height: 56px;
  gap: 8px;

  &:hover {
    background-color: ${theme.colors.SLATE100};
  }

  &:active {
    border-color: ${theme.colors.BLUE300};
  }

  img {
    max-width: 18px;
  }
`;

export const OutlineButtonWithImage: React.FC<{
  text: string;
  image?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  testId: string;
  type?: "button" | "submit" | "reset";
}> = ({ text, image, onClick, testId, type }) => {
  return (
    <OutlineButton onClick={onClick} data-testid={testId} type={type}>
      {image && <img src={image} alt="Image" />}
      {text}
    </OutlineButton>
  );
};
