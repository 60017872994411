import React from "react";
import ContentLoader from "react-content-loader";

export const NavbarNavItemLoader = () => (
  <ContentLoader
    speed={2}
    width={140}
    height={24}
    viewBox="0 0 140 24"
    backgroundColor="#1f1f1f"
    foregroundColor="#7d7d7d"
  >
    <rect x="0" y="0" rx="4" ry="4" width="140" height="24" />
  </ContentLoader>
);
