import React, { FC } from "react";
import { Text } from "@ifgengineering/component-library";
import { PromoWrapper, Spacer, CharityLogo } from "./styled";
import { Charity } from "./types";

const LandingPage: FC<{ charity: Charity }> = ({ charity }) => {
  return (
    <PromoWrapper key="charity-promo">
      <Spacer />
      <CharityLogo src={charity.logoKey} alt={charity.displayName} />
      <Spacer />
      <Text type="T32" color="WHITE" fontFamily="archiabold">
        Write your Islamic Will in just 20 minutes - sponsored by{" "}
        {charity.displayName}.
      </Text>
      <Spacer />
      <Text type="S18" color="WHITE">
        {
          "Once you sign up, you will be able to access all of IFG's products in a secure environment, including Wealth, Wills and Zakat"
        }
      </Text>
    </PromoWrapper>
  );
};

export default LandingPage;
