import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import actions from "./commitmentPreview.types";
import {
  CommitmentPreviewPayload,
  GetCommitmentPreviewInput,
} from "@ifgengineering/client-invest-sdk";
import { clientInvestSdk } from "@utils/clientInvestSdk";

export const fetchCommitmentPreviewAPI = createAsyncThunk<
  CommitmentPreviewPayload,
  GetCommitmentPreviewInput,
  { rejectValue: unknown }
>(
  actions.GET_COMMITMENT_PREVIEW,
  async ({ commitmentId, amount, instalmentPlan }, { rejectWithValue }) => {
    try {
      return await clientInvestSdk.getCommitmentPreview({
        commitmentId,
        amount,
        instalmentPlan,
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const clearCommitmentPreview = createAction(
  actions.CLEAR_COMMITMENT_PREVIEW
);
