import { TNotification } from "@ifgengineering/client-notifications-sdk";
import React, { FC } from "react";
import { NotificationBadgeHeader, NotificationScrollArea } from "./styled";
import NotificationListItem from "./NotificationListItem";
import { Text } from "@ifgengineering/component-library";
import { handleNotificationCta } from "@components/Notifications/helpers";
import useAnalytics from "../../../helpers/useAnalytics";

interface Props {
  notifications: TNotification[];
  setShow: (show: boolean) => void;
}

const NotificationList: FC<Props> = ({ notifications, setShow }) => {
  const notificationCTAClicked = useAnalytics("notificationCTAClicked");

  return (
    <>
      <NotificationBadgeHeader>
        <Text type="H16" color="SLATE900">
          Notifications
        </Text>
      </NotificationBadgeHeader>
      <NotificationScrollArea>
        {notifications.map((notification) => {
          const { id, sentAt, template } = notification;
          const webCta = template?.cta?.urls?.web;
          const onClick = async () => {
            handleNotificationCta(notification);
            setShow(false);
            (await notificationCTAClicked)({ name: template.name });
          };

          return (
            <NotificationListItem
              onClick={webCta ? onClick : undefined}
              ctaLabel={template?.cta?.label}
              key={id}
              description={template.description}
              heading={template.heading}
              icon={template.icon}
              sentAt={sentAt}
            />
          );
        })}
      </NotificationScrollArea>
    </>
  );
};

export default NotificationList;
