import { StylesConfig } from "react-select";
import { theme } from "../../../styles";
import { DropDownValue } from "../SearchSelect.component";

export const customStyles: StylesConfig<DropDownValue, false> = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor:
      state.isFocused || state.data === state.selectProps.value
        ? theme.colors.SLATE100
        : theme.colors.WHITE,
    color: theme.colors.SLATE700,
    fontSize: "14px",
  }),
  control: (provided, state) => ({
    ...provided,
    fontFamily: "Inter, sans-serif",
    fontWeight: 400,
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    padding: "0 8px",
    height: "56px",
    border: `1px solid ${
      state.isFocused ? theme.colors.BLUE600 : theme.colors.SLATE300
    }`,
    width: "100%",
    display: "flex",
    transition: "all 0.3s",
    boxShadow: state.isFocused ? "0 1rem 2rem rgba(black, 0.1)" : "none",
    "&:hover": {
      borderColor: "none",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.colors.SLATE800,
  }),
  menu: (provided) => ({
    ...provided,
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    color: "grey",
  }),
};
