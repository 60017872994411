import React, { FC } from "react";
import { navigate } from "gatsby";
import { Banner } from "@ifgengineering/component-library";

interface Props {
  verificationInProgress: boolean;
}

export const VerifyIdentityBanner: FC<Props> = ({ verificationInProgress }) => {
  const text = verificationInProgress
    ? "Your identity is being verified. This usually takes a few minutes but can take up to 48 hours."
    : "You need to verify your Identity before you can start investing.";

  return (
    <Banner
      testId="verify-identity-link"
      text={text}
      {...(verificationInProgress ? {} : { CTAText: "Verify Now" })}
      onClickCTA={() => navigate("/app/identity")}
    />
  );
};
