import { Text } from "@ifgengineering/component-library";
import React, { FC } from "react";
import styled from "styled-components";

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
`;

const EmptyState: FC = () => (
  <EmptyStateContainer>
    <Text color="SLATE900" type="S16">
      No Notifications Yet
    </Text>
    <Text color="SLATE700" type="P14">
      We’ll notify you if there are any new notifications
    </Text>
  </EmptyStateContainer>
);

export default EmptyState;
