import {
  TNotification,
  TUpdateNotificationPayload,
} from "@ifgengineering/client-notifications-sdk";
import { navigate } from "gatsby";
import { logException } from "@helpers/logException/logException";
import getPlatformName from "../../helpers/getPlatformName";

export const getNotificationsToUpdate = (
  notifications: TNotification[]
): TUpdateNotificationPayload[] =>
  notifications
    .filter((notification) => notification.status === "UNREAD")
    .map((notification) => ({
      id: notification.id,
      status: "READ",
    }));

export const propTypeCallbacks: { [x: string]: (n: TNotification) => void } = {
  KYC_REMINDER: (n: TNotification) => {
    const platform = getPlatformName();
    const webCta = n.template?.cta?.urls?.web;

    switch (platform) {
      case "desktop":
        handleCtaNavigation(webCta);
        break;
      case "iOS":
        window.open((n.properties?.iosURL as string) || "");
        break;
      case "android":
        window.open((n.properties?.androidURL as string) || "");
        break;
      default:
        handleCtaNavigation(webCta);
        break;
    }
  },
};

export const handleNotificationCta = (notification: TNotification): void => {
  const { properties } = notification;
  if (properties && propTypeCallbacks[properties.type as string]) {
    propTypeCallbacks[properties.type](notification);
    return;
  }

  const { template } = notification;
  const webCta = template?.cta?.urls?.web;

  handleCtaNavigation(webCta);
};

export const handleCtaNavigation: (cta?: string) => void = (cta) => {
  if (!cta) {
    return;
  }

  // cta structure: <environment>/<page>/?<id>
  const parts = cta.split("/");

  if (parts.length < 2) {
    return logException({
      tag: "handleCtaNavigation",
      message: "Invalid CTA structure",
      exception: new Error("Invalid CTA structure"),
    });
  }

  const env = parts[0];
  const page = parts[1];
  const id = parts[2];

  if (env === "external") return window.open(parts.slice(1).join("/"));

  const navigationParameters = {
    liveDeals: "deals",
    payments: "pending-payments",
    portfolio: "portfolio",
    onboarding: "onboarding",
    dealDetails: `deals/${id}`,
    makeCommitment: `deals/${id}/commitment`,
    bankDetails: `bank-details/${id}`,
  };

  const route =
    navigationParameters[page as keyof typeof navigationParameters] || "deals";
  navigate(`/app/${route}`);
};
