import React, { FC, useState } from "react";
import { Formik } from "formik";
import { BackContainer, FieldStyled, TitleSpacer } from "./styled";
import { getEmailSchema } from "./validationSchemas";
import { toast } from "react-toastify";
import Icon from "@icon-park/react/es/all";
import { useClientAuthComponents } from "../../../../Context";
import axios from "axios";
import {
  Input,
  FilledButton as Button,
  Text,
  theme,
} from "@ifgengineering/component-library";

interface EmailFormProps {
  title: string;
  onChange: (email: string) => void;
  onClickBack: VoidFunction;
  handleSuccess: VoidFunction;
}

const AppleLinkEmailForm: FC<EmailFormProps> = ({
  title,
  onChange,
  onClickBack,
  handleSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { generateOTP, logException } = useClientAuthComponents();

  const startAppleLinkEmailProcess = async (email: string) => {
    setIsLoading(true);

    try {
      await generateOTP(email);
    } catch (e: any) {
      if (axios.isAxiosError(e) && e.response && e.response.status !== 404) {
        toast.error("Something went wrong. Try again or contact support");

        logException({
          email,
          tag: "appleLinkFlow_Email_Form",
          exception: e as any,
        });

        throw e;
      }
    }

    handleSuccess();

    setIsLoading(false);
  };

  return (
    <div data-testid="apple-otp-email">
      <Formik
        enableReinitialize
        validationSchema={getEmailSchema}
        initialValues={{ email: "" }}
        onSubmit={({ email }) => {
          startAppleLinkEmailProcess(email);
        }}
      >
        {({
          setFieldValue,
          values,
          errors,
          touched,
          submitForm,
          isSubmitting,
        }) => {
          const disableSubmitBtn = isLoading || isSubmitting;
          return (
            <>
              <BackContainer onClick={onClickBack}>
                <Icon
                  type="ArrowLeft"
                  size={18}
                  theme="outline"
                  fill={theme.colors.BLUE600}
                />
                <Text type="S16" color="BLUE600" fontFamily="Inter">
                  Back
                </Text>
              </BackContainer>
              <br />
              <TitleSpacer>
                <Text type="T32" color="SLATE800" fontFamily="archiasemibold">
                  {title}
                </Text>
              </TitleSpacer>
              <FieldStyled>
                <Input
                  testId="apple-link-email-input"
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                    onChange(e.target.value);
                  }}
                  errorMessage={touched.email ? errors.email : undefined}
                  heading="Email of existing account"
                />
              </FieldStyled>
              <Button
                testId="submit-email-button"
                type="button"
                height="56px"
                text="Submit Email"
                color="darkBlue"
                onClick={submitForm}
                disabled={disableSubmitBtn}
                isLoading={disableSubmitBtn}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default AppleLinkEmailForm;
