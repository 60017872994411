import React from "react";
import TopBar from "./TopBar";
import { Container, PageContainer, RightPanelContainer } from "./styled";
import { NavMenuItemsPosition } from "../constants";
import { MenuTemplateItem, WealthMainPageType } from "../types";
import Sidebar from "./Sidebar";

type DesktopNavbarProps = {
  children?: JSX.Element;
  topbarButtons?: JSX.Element | null;
  template: MenuTemplateItem[];
  page: WealthMainPageType;
  hideTopBar?: boolean;
  logoutCallback: () => void;
};

const DesktopNavbar: React.FC<DesktopNavbarProps> = ({
  children,
  topbarButtons,
  template,
  logoutCallback,
  page,
  hideTopBar,
}) => {
  const topMenu = template.filter(
    ({ position }) => position === NavMenuItemsPosition.MAIN
  );
  const bottomMenu = template.filter(
    ({ position }) => position === NavMenuItemsPosition.SECONDARY
  );

  return (
    <Container>
      <RightPanelContainer id="RightPanelContainer">
        {!hideTopBar && (
          <>
            {topbarButtons ? (
              <TopBar page={page}>{topbarButtons}</TopBar>
            ) : (
              <TopBar page={page === "Invest" ? "Cur8 Capital" : page} />
            )}
          </>
        )}
        <PageContainer hideTopBar={hideTopBar}>{children}</PageContainer>
      </RightPanelContainer>
    </Container>
  );
};

export default DesktopNavbar;
