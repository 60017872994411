import React, { FC } from "react";
import {
  IconContainer,
  LoaderContainer,
  NotificationBadge,
  NotificationListContainer,
  Wrapper,
} from "./styled";
import Icon from "@icon-park/react/es/all";
import { InlineLoader, theme } from "@ifgengineering/component-library";
import { TNotification } from "@ifgengineering/client-notifications-sdk";
import EmptyState from "./EmptyState";
import NotificationList from "./NotificationList";

interface DesktopNotificationList {
  isOpen: boolean;
  anyPendingNotification: boolean;
  neverLoadedNotification: boolean;
  notifications: TNotification[];
  setIsOpen: (setIsOpen: boolean) => void;
}

export const DesktopNotificationList: FC<DesktopNotificationList> = ({
  isOpen,
  notifications,
  neverLoadedNotification,
  anyPendingNotification,
  setIsOpen,
}) => {
  const renderContent = (notifications: TNotification[]) => {
    if (neverLoadedNotification) {
      return (
        <LoaderContainer>
          <InlineLoader size={30} thickness={3} />
        </LoaderContainer>
      );
    }

    const nothingToShow = !neverLoadedNotification && !notifications.length;
    const whatToShow = nothingToShow ? (
      <EmptyState />
    ) : (
      <NotificationList setShow={setIsOpen} notifications={notifications} />
    );

    return <NotificationListContainer>{whatToShow}</NotificationListContainer>;
  };

  return (
    <Wrapper>
      <IconContainer onClick={() => setIsOpen(!isOpen)} highlight={isOpen}>
        {anyPendingNotification && <NotificationBadge />}
        <Icon
          type="Remind"
          fill={isOpen ? theme.colors.SLATE700 : theme.colors.SLATE200}
          size="24"
        />
      </IconContainer>
      {isOpen && renderContent(notifications)}
    </Wrapper>
  );
};

export default DesktopNotificationList;
