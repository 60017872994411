import React, { FC, useState } from "react";
import GetEmailForm from "./GetEmailForm";
import MagicCodeForm from "./MagicCodeForm";

interface MagicCodeProps {
  loginRedirect: string;
  signupURL?: string;
  handleCancel: () => void;
}

const MagicCode: FC<MagicCodeProps> = ({
  handleCancel,
  signupURL,
  loginRedirect,
}) => {
  const [showMagicCodeForm, setShowMagicCodeForm] = useState(false);
  const [email, setEmail] = useState("");

  return showMagicCodeForm ? (
    <MagicCodeForm
      loginRedirect={loginRedirect}
      email={email}
      handleBackButton={() => setShowMagicCodeForm(false)}
    />
  ) : (
    <GetEmailForm
      handleBackButton={handleCancel}
      signupURL={signupURL}
      email={email}
      onChange={(email) => setEmail(email)}
      handleSuccess={() => setShowMagicCodeForm(true)}
    />
  );
};

export default MagicCode;
