import { theme } from "@ifgengineering/component-library";
import styled from "styled-components";

export const TitleSpacer = styled.div`
  padding-bottom: 40px;
`;

export const InputsContainer = styled.div`
  padding: 5px 0 15px 0;
`;

export const TimerContainer = styled.div`
  display: inline-block;
  width: 50px;
`;

export const ErrorContainer = styled.div`
  height: 18px;
  display: flex;
  gap: 7px;
  align-items: center;
  padding: 7px 0 0 0;
`;

export const FieldStyled = styled.div`
  & input {
    border: 1px solid ${theme.colors.SLATE300};
    border-radius: 4px;
    margin: 0 20px 0 0;
    padding: 9px;
    min-width: 36px;
    height: 53px;
  }

  & input:focus {
    border: 1px solid ${theme.colors.BLUE600};
    outline-color: ${theme.colors.BLUE600};
  }
`;
