import React, { FC, useEffect } from "react";
import { TNotification } from "@ifgengineering/client-notifications-sdk";
import { useDispatch, useSelector } from "react-redux";
import {
  getPendingNotificationSelector,
  neverLoadedNotificationSelector,
} from "@state/notification/notification.selectors";
import {
  putNotificationsThunk,
  updateNotificationsAction,
} from "@state/notification/notification.actions";
import { getNotificationsToUpdate } from "./helpers";
import DesktopNotificationList from "./desktop";

interface Props {
  notifications: TNotification[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const Notifications: FC<Props> = ({ notifications, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const anyPendingNotification = useSelector(getPendingNotificationSelector);
  const neverLoadedNotification = useSelector(neverLoadedNotificationSelector);

  useEffect(() => {
    if (!isOpen && !anyPendingNotification) {
      return;
    }

    const notificationsToUpdate = getNotificationsToUpdate(notifications);

    if (notificationsToUpdate.length) {
      dispatch(putNotificationsThunk(notificationsToUpdate));
      dispatch(updateNotificationsAction(notificationsToUpdate));
    }
  }, [isOpen]);

  return (
    <DesktopNotificationList
      anyPendingNotification={anyPendingNotification}
      neverLoadedNotification={neverLoadedNotification}
      notifications={notifications}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
    />
  );
};

export default Notifications;
