import React from "react";
import { ToggleProps } from "./types";
import { StyledText, ToggleButton, ToggleContainer, Wrapper } from "./styled";
import { defaultColors } from "./constants/defaultColors";

const Toggle: React.FC<ToggleProps> = ({
  size = "default",
  colors = defaultColors,
  labelColor = "SLATE700",
  checked,
  setChecked,
  label,
}) => (
  <Wrapper>
    <ToggleContainer
      data-testid="toggle-container"
      size={size}
      checked={checked}
      colors={colors}
      onClick={() => setChecked(!checked)}
    >
      <ToggleButton
        data-testid="toggle-button"
        size={size}
        checked={checked}
        colors={colors}
      />
    </ToggleContainer>
    {label && (
      <StyledText
        onClick={() => setChecked(!checked)}
        type={size === "large" ? "P16" : "P14"}
        color={labelColor}
      >
        {label}
      </StyledText>
    )}
  </Wrapper>
);

export default Toggle;
