import { Entity, FileInterface } from "@ifgengineering/client-invest-sdk";
import {
  deleteEntityDocumentAPI,
  fetchEntitiesAPI,
  postEntityAPI,
  uploadEntityDocumentAPI,
} from "./entity.actions";
import { signOutAsync } from "@state/auth/auth.actions";

export interface EntityState {
  neverLoaded: boolean;
  loading: boolean;
  data: Entity[];
}

export const INITIAL_STATE: EntityState = {
  neverLoaded: true,
  loading: false,
  data: [],
};

const entityReducer = (state = INITIAL_STATE, action: any): EntityState => {
  switch (action.type) {
    case signOutAsync.fulfilled.type:
      return INITIAL_STATE;
    case fetchEntitiesAPI.pending.type:
      return {
        ...state,
        loading: true,
      };
    case fetchEntitiesAPI.rejected.type:
      return {
        ...state,
        loading: false,
      };
    case fetchEntitiesAPI.fulfilled.type:
      return {
        ...state,
        neverLoaded: false,
        loading: false,
        data: action.payload,
      };
    case postEntityAPI.fulfilled.type: {
      const { data } = state;
      const existingDataIndex = data.findIndex(
        (item) => item.id === action.payload.id
      );

      return {
        ...state,
        neverLoaded: false,
        loading: false,
        data:
          existingDataIndex !== -1
            ? [
                ...data.slice(0, existingDataIndex),
                action.payload,
                ...data.slice(existingDataIndex + 1),
              ]
            : [...data, action.payload],
      };
    }

    case deleteEntityDocumentAPI.fulfilled.type: {
      const { data } = state;
      const { entityId, documentId, documentType } = action.payload;

      const entityIndex = data.findIndex((entity) => entity.id === entityId);

      if (entityIndex === -1) {
        return state;
      }

      const entity = data[entityIndex];
      const documents = entity[documentType as keyof Entity] as FileInterface[];

      if (!documents) {
        return state;
      }

      const updatedDocumentList = documents.filter((d) => d.id !== documentId);
      const updatedEntity = {
        ...entity,
        [documentType]: updatedDocumentList,
      };

      return {
        ...state,
        data: [
          ...data.slice(0, entityIndex),
          updatedEntity,
          ...data.slice(entityIndex + 1),
        ],
      };
    }

    case uploadEntityDocumentAPI.fulfilled.type: {
      const { data } = state;
      const { entityId, document, documentType } = action.payload;

      const entityIndex = data.findIndex((entity) => entity.id === entityId);

      if (entityIndex === -1) {
        return state;
      }

      const entity = data[entityIndex];
      const documents = entity[documentType as keyof Entity] as FileInterface[];

      if (!documents) {
        return state;
      }

      const updatedDocumentList = [...documents, document];

      const updatedEntity = {
        ...entity,
        [documentType]: updatedDocumentList,
      };

      return {
        ...state,
        data: [
          ...data.slice(0, entityIndex),
          updatedEntity,
          ...data.slice(entityIndex + 1),
        ],
      };
    }

    default:
      return state;
  }
};

export default entityReducer;
