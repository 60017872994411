import { theme } from "@ifgengineering/component-library";
import styled from "styled-components";

export const StyledInput = styled.input<{ hasErrored?: boolean }>`
  border-radius: 4px;
  margin: 0px 20px 0px 0px;
  padding: 9px;
  min-width: 36px;
  height: 53px;
  border: ${({ hasErrored }) =>
    hasErrored
      ? `1px solid ${theme.colors.ERROR800}`
      : `1px solid ${theme.colors.SLATE300}`} !important;
`;
