import React from "react";
import Icon from "@icon-park/react/es/all";
import { Container } from "../../styled";
import {
  FilledButton,
  Stack,
  Text,
  theme,
} from "@ifgengineering/component-library";

interface AppleLinkStatusProps {
  onContinue?: VoidFunction;
  onLoginWithEmail?: VoidFunction;
}

export const AppleLinkStatusSuccess: React.FC<AppleLinkStatusProps> = ({
  onContinue,
}) => {
  return (
    <Container gap={16}>
      <Stack orientation="horizontal" gap={8}>
        <Icon
          type="CheckOne"
          size="24px"
          theme="filled"
          fill={theme.colors.GREEN800}
        />
        <Text type="T18" color="SLATE800" fontFamily="archiabold">
          Account Successfully Linked
        </Text>
      </Stack>
      <Text type="P16" color="SLATE800">
        You can now use your Apple ID to sign into your Cur8 account
      </Text>
      <FilledButton
        color="darkBlue"
        height="56px"
        text="Continue"
        onClick={onContinue}
      />
    </Container>
  );
};

export const AppleLinkStatusFailure: React.FC<AppleLinkStatusProps> = ({
  onLoginWithEmail,
}) => {
  return (
    <Container gap={16}>
      <Stack orientation="horizontal" gap={8}>
        <Icon
          type="CloseOne"
          size="24px"
          theme="filled"
          fill={theme.colors.ERROR800}
        />
        <Text type="T18" color="SLATE800" fontFamily="archiabold">
          Account Linking Failed
        </Text>
      </Stack>

      <Text type="P16" color="SLATE800">
        We were unable to link your Apple account to your Cur8 account using
        your email. Please try again later or{" "}
        <Text
          type="P16"
          color="BLUE600"
          as="a"
          href="https://cur8.capital/contact-us"
          target="_blank"
        >
          contact us
        </Text>{" "}
        if the problem persists.
      </Text>

      <FilledButton
        color="darkBlue"
        height="56px"
        text="Login with Email"
        onClick={onLoginWithEmail}
      />
    </Container>
  );
};
