import React, { createContext, FC, useContext, useState } from "react";
import { AxiosResponse } from "axios";
import {
  AppleLinkEmailInput,
  AppleLinkEmailOutput,
  VerifyEmailInput,
} from "@ifgengineering/client-auth-sdk";
export interface IAuthComponentsContext {
  children?: React.ReactNode;
  charity?: any;
  isCur8InWealthEnabled: boolean;
  isLayoutBEnabled: boolean;
  isMagicCodeEnabled: boolean;
  logException: ({
    email,
    tag,
    exception,
  }: {
    email?: string;
    tag?: string;
    exception: string;
  }) => void;
  loginAction: any;
  recaptchaClientKey: string;
  resetPassword: ({
    email,
    newPassword,
    token,
  }: {
    email: string;
    newPassword: string;
    token: string;
  }) => Promise<void>;
  sendResetPasswordLink: (email: string) => Promise<
    AxiosResponse<{
      email: string;
      origin: string;
    }>
  >;
  signupAction: any;
  useAnalytics: any;
  verifyEmail: (payload: VerifyEmailInput) => Promise<void>;
  passwordlessStart: (
    email: string,
    isSignup?: boolean | undefined
  ) => Promise<void>;
  passwordlessVerify: (
    email: string,
    otp: string,
    isSignup?: boolean | undefined
  ) => Promise<void>;
  setAppleAuthData: (data: IAppleAuthData) => void;
  appleAuthData?: IAppleAuthData;
  generateOTP: (email: string) => Promise<void>;
  appleLinkEmail: (
    data: AppleLinkEmailInput
  ) => Promise<AxiosResponse<AppleLinkEmailOutput>>;
}

interface IAppleAuthData {
  isPrivateEmail?: boolean;
  authorizationCode?: string;
  linkEmail?: string;
  linkOtp?: string;
}

export const AuthComponentsContext = createContext(
  {} as IAuthComponentsContext
);

export const AuthComponentsProvider: FC<IAuthComponentsContext> = ({
  charity,
  children,
  isCur8InWealthEnabled,
  isLayoutBEnabled,
  isMagicCodeEnabled,
  logException,
  loginAction,
  recaptchaClientKey,
  resetPassword,
  sendResetPasswordLink,
  signupAction,
  useAnalytics,
  verifyEmail,
  passwordlessStart,
  passwordlessVerify,
  generateOTP,
  appleLinkEmail,
}) => {
  const [appleAuthData, setAppleAuthData] = useState<IAppleAuthData>({
    isPrivateEmail: false,
  });
  return (
    <AuthComponentsContext.Provider
      value={{
        charity,
        isCur8InWealthEnabled,
        isLayoutBEnabled,
        isMagicCodeEnabled,
        logException,
        loginAction,
        recaptchaClientKey,
        resetPassword,
        sendResetPasswordLink,
        signupAction,
        useAnalytics,
        verifyEmail,
        passwordlessStart,
        passwordlessVerify,
        generateOTP,
        appleLinkEmail,
        appleAuthData,
        setAppleAuthData: (data) => {
          setAppleAuthData((prevState) => ({ ...prevState, ...data }));
        },
      }}
    >
      {children}
    </AuthComponentsContext.Provider>
  );
};

export const useClientAuthComponents = () => useContext(AuthComponentsContext);
