import { RootState } from "../rootReducer";
import { TNotification } from "@ifgengineering/client-notifications-sdk";

export const getIsLoadingNotificationSelector = (state: RootState): boolean =>
  state.notification.loading;

export const getNotificationsSelector = (state: RootState): TNotification[] =>
  state.notification.data;

export const getPendingNotificationSelector = (state: RootState): boolean => {
  return state.notification.data.some(
    (notification) => notification.status === "UNREAD"
  );
};

export const neverLoadedNotificationSelector = (state: RootState): boolean =>
  state.notification.neverLoadedNotifications;
