const AuthActionTypes = {
  SIGN_IN: "SIGN_IN",
  SIGN_IN_GOOGLE: "SIGN_IN_GOOGLE",
  SIGN_IN_APPLE: "SIGN_IN_APPLE",
  SIGN_UP_APPLE: "SIGN_UP_APPLE",
  SIGN_OUT: "SIGN_OUT",
  SIGN_UP: "SIGN_UP",
  GET_USER: "GET_USER",
  REFRESH_SESSION_FAILURE: "REFRESH_SESSION_FAILURE",
  CLEAR_ERROR_AND_RESULT: "CLEAR_ERROR_AND_RESULT",
  GET_TWO_FACTOR_AUTH_DATA: "GET_TWO_FACTOR_AUTH_DATA",
  GET_AUTH_ACCOUNT_DATA: "GET_AUTH_ACCOUNT_DATA",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_TWO_FACTOR_AUTH: "UPDATE_TWO_FACTOR_AUTH",
  SET_VIEWED_INVESTOR_CERTIFICATE_FORM: "SET_VIEWED_INVESTOR_CERTIFICATE_FORM",
  AUTH_ERROR: "AUTH_ERROR",
  RESET: "RESET",
};

export default AuthActionTypes;
