import React, { useEffect } from "react";

const TrustBox: React.FC = () => {
  const ref = React.useRef(null);

  useEffect(() => {
    // @ts-ignore
    if (window.Trustpilot) {
      // @ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5cef9cf83f0ff4000123ec4a"
      data-style-height="100%"
      data-theme="dark"
      data-style-width="180px"
    >
      <a
        href="https://uk.trustpilot.com/review/islamicfinanceguru.com"
        target="_blank"
        rel="noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

export default TrustBox;
