import { AxiosPromise, AxiosStatic } from "axios";
import { FileInterface, KycProfile } from "../types";

export const uploadKYCDocument =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (file: File): AxiosPromise<FileInterface> => {
    const formData = new FormData();
    formData.append("file", file);

    return await appAxios.post(`${serverURL}/yoti/document`, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

export const deleteKYCDocument =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (documentId: string): AxiosPromise<void> =>
    await appAxios.delete(`${serverURL}/yoti/document/${documentId}`, {
      withCredentials: true,
    });

export const postSourceOfFundDescription =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (sourceOfFundDescription: string): AxiosPromise<KycProfile> => {
    return await appAxios.post(
      `${serverURL}/yoti/sourceOfFund`,
      {
        sourceOfFundDescription,
      },
      {
        withCredentials: true,
      }
    );
  };
