import styled from "styled-components";
import { theme, MEDIA_QUERIES } from "@ifgengineering/component-library";
import Icon from "@icon-park/react/es/all";
import { MenuTemplateItem } from "../types";

export const NavMobileContainer = styled.div`
  z-index: 15;
  position: relative;
  display: flex;
  justify-content: inherit;
  padding: 0 10px;
  align-items: center;
  flex-direction: row;
  width: 100%;
  min-height: 56px;
  height: 56px;
`;

export const StyledLink = styled.div<Partial<MenuTemplateItem>>`
  gap: 8px;
  align-items: center;
  display: flex;
  cursor: pointer;
  padding: ${({ padding }) => padding || "20px"};
  position: relative;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  border-radius: ${({ rounded }) => rounded && "8px"};
  background-color: ${({ selected, selectedBgColor = "WHITE" }) =>
    selected ? theme.colors[selectedBgColor] : theme.colors.WHITE};
  &:hover {
    background-color: ${({ selected, hoverBgColor = "SLATE200" }) =>
      selected ? theme.colors[hoverBgColor] : theme.colors.SLATE100};
  }
`;

export const LabelTag = styled.span<{ isSelected?: boolean }>`
  border-radius: 2px;
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 400;
  background-color: ${({ isSelected }) =>
    isSelected ? theme.colors.SLATE800 : theme.colors.INDIGO900};
  & > p {
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 1px;
  }
  @media ${MEDIA_QUERIES.PHONE} {
    font-size: 8px;
    border-width: 1px;
    padding: 0.8px 0.7em;
    right: 10px;
    top: 10px;
  }
`;

export const StyledMobileImage = styled.img`
  width: auto;
  height: 24px;
`;

export const PageContainer = styled.div<{ hideTopBar?: boolean }>`
  width: 100%;
  display: flex;
  flex-grow: 1;
`;

export const MobilePageContainer = styled.div`
  overflow: scroll;
  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const StyledImage = styled.img`
  width: 24.92px;
  height: 24px;
`;

export const ImageLogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
  gap: 2px;
`;

export const TopBarContainer = styled.div<{ isMobileNav?: boolean }>`
  box-sizing: border-box;
  background: ${theme.colors.BLACK};
  padding: ${({ isMobileNav }) => (isMobileNav ? "1rem" : "1em 6em")};
  display: grid;
  grid-template-columns: 4fr 8fr 2fr;
  gap: 2em;
  align-items: "stretch";
  min-height: 72px;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const MobileFilterContainer = styled.button`
  display: flex;
  gap: 0.55em;
  align-items: center;
`;

export const CompareButtonContainer = styled.div`
  display: flex;

  justify-content: start;
  gap: 1em;
`;

export const CompareDesktopNavBtn = styled.button<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? theme.colors.BLUE600 : "")};
  color: ${theme.colors.WHITE};
  border-radius: 0.5em;
  padding: 0.5em 1em;
  width: fit-content;
`;

export const MobileIconContainer = styled.div`
  gap: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: -12px;
  background-color: ${theme.colors.WHITE};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HelpBox = styled.div`
  position: absolute;
  top: 0;
  right: 40px;
  display: flex;
  gap: 40px;
  align-items: flex-end;
`;

export const NotificationBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: ${theme.colors.WHITE};
  border-radius: 0 0 10px 10px;
  cursor: pointer;
`;

export const HideOverflow = styled.div`
  overflow: hidden;
`;

export const Separator = styled.div`
  border-top: 0.5px solid ${theme.colors.BLUE200};
  margin: 0 20px;
`;

export const RightPanelContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
`;

export const DropDownContainer = styled.div`
  box-shadow: 0 2px 8px ${theme.colors.SLATE300};
  background-color: ${theme.colors.WHITE};
  position: absolute;
  top: 56px;
  border-radius: 4px;
`;

export const MobileMenuContainer = styled.div`
  width: 134px;
  height: 36px;
  display: flex;
  margin-left: 20px;
`;

export const CompareNav = styled.div`
  z-index: 5;
  width: 100%;
  min-height: 64px;
  height: 64px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 0;
`;

export const CompareNavButton = styled.button<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ selected }) =>
    selected ? theme.colors.SLATE900 : "white"};
  width: 50%;
`;
