import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import {
  Text,
  theme,
  NavigationBar,
  LinkButton,
  TabContext,
} from "@ifgengineering/component-library";
import FloatingMenu, {
  FloatingMenuItemProps,
} from "@components/FloatingMenu/FloatingMenu.component";
import PoweredByIfgLogo from "@assets/images/powered-by-ifg.png";
import {
  MenuContainer,
  NavChildrenContainer,
  NavigationBarContainer,
  FloatingMenuContainer,
  StyledIcon,
} from "./styled";
import { signOutAsync } from "../../state/auth/auth.actions";
import {
  getFloatMenuItems,
  getHowItWorksItems,
  getMenuItems,
  getSelectedMenuItem,
  mainMenuNavigation,
} from "./helpers";
import {
  investorSelector,
  neverLoadedInvestorSelector,
} from "@state/investor/investor.selectors";
import Notifications from "@components/Notifications";
import { getNotificationsSelector } from "@state/notification/notification.selectors";
import { NavbarNavItemLoader } from "./NavbarItemLoader";

const NavBar: ({
  showTutorialModal,
}: {
  showTutorialModal: () => void;
}) => JSX.Element = ({ showTutorialModal }) => {
  const [isMyMenuOpen, setIsMyMenuOpen] = useState(false);
  const [isHowItWorksOpen, setIsHowItWorksOpen] = useState(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const signOutUser = () => dispatch(signOutAsync());
  const investor = useSelector(investorSelector);
  const neverLoadedInvestor = useSelector(neverLoadedInvestorSelector);
  const notifications = useSelector(getNotificationsSelector);

  const userInfoToDisplay = investor?.firstName
    ? `${investor?.firstName}`
    : // Display the part before the @ instead of the name, if we don't have it
      investor?.email?.substring(0, investor?.email?.lastIndexOf("@"));

  const renderDropDownMenu = ({
    title,
    items,
    isOpen,
    onClick,
  }: {
    title?: string;
    items: Array<Array<FloatingMenuItemProps>>;
    isOpen: boolean;
    onClick: () => void;
  }) => {
    const iconType = isOpen ? "Up" : "Down";
    const iconColor = isOpen
      ? theme.colors["SLATE900"]
      : theme.colors["SLATE200"];
    return (
      <FloatingMenuContainer
        isOpen={isOpen}
        onClick={onClick}
        data-testid="dropdown-menu-button"
      >
        <Text type="S16" color={isOpen ? "SLATE900" : "SLATE200"}>
          {title}
        </Text>
        <StyledIcon type={iconType} size="24px" fill={iconColor} />
        {isOpen && (
          <MenuContainer>
            <FloatingMenu collectionOfItems={items} />
          </MenuContainer>
        )}
      </FloatingMenuContainer>
    );
  };

  const handleMyMenuClick = () => {
    setIsHowItWorksOpen(false);
    setIsNotificationMenuOpen(false);
    setIsMyMenuOpen(!isMyMenuOpen);
  };

  const handleHowItWorksClick = () => {
    setIsMyMenuOpen(false);
    setIsNotificationMenuOpen(false);
    setIsHowItWorksOpen(!isHowItWorksOpen);
  };

  const handleNotificationsMenuClick = (isOpen: boolean) => {
    setIsNotificationMenuOpen(isOpen);
    setIsHowItWorksOpen(false);
    setIsMyMenuOpen(false);
  };
  const { selectTab } = useContext(TabContext);

  return (
    <NavigationBarContainer>
      <NavigationBar
        navItemsTextColor="WHITE"
        selectedNavItemsTextColor="WHITE"
        bgColor="BLACK"
        selectedNavItemsBgColor="BLUE600"
        selectedItemId={getSelectedMenuItem()}
        pageName={"Invest"}
        secondaryLogo={PoweredByIfgLogo}
        navItems={getMenuItems(true)}
        onSelectNavItem={(item) => mainMenuNavigation(item.id)}
      >
        <NavChildrenContainer>
          <Notifications
            notifications={notifications}
            isOpen={isNotificationMenuOpen}
            setIsOpen={handleNotificationsMenuClick}
          />
          <LinkButton
            text="Membership"
            textSize="S16"
            textColor="SLATE200"
            icon="Diamonds"
            iconColor="GOLD500"
            style={{ gap: "4px" }}
            onClick={() => {
              const tabId = "membership";
              navigate(`/app/account?tab=${tabId}`);
              selectTab(tabId);
            }}
          />
          {renderDropDownMenu({
            title: "How it Works",
            items: [getHowItWorksItems(showTutorialModal)],
            isOpen: isHowItWorksOpen,
            onClick: handleHowItWorksClick,
          })}
          {neverLoadedInvestor && <NavbarNavItemLoader />}
          {!neverLoadedInvestor &&
            renderDropDownMenu({
              title: userInfoToDisplay,
              items: [
                ...getFloatMenuItems({
                  logoutCallback: signOutUser,
                  onClickMyAccount: () => {
                    const tabId = "profile";
                    navigate(`/app/account?tab=${tabId}`);
                    selectTab(tabId);
                  },
                  onClickMembership: () => {
                    const tabId = "membership";
                    navigate(`/app/account?tab=${tabId}`);
                    selectTab(tabId);
                  },
                }),
              ],
              isOpen: isMyMenuOpen,
              onClick: handleMyMenuClick,
            })}
        </NavChildrenContainer>
      </NavigationBar>
    </NavigationBarContainer>
  );
};

export default NavBar;
