import Card from "@components/Card";
import React, { FC } from "react";
import Icon from "@icon-park/react/es/all";
import { CloseButton, Overlay, Window } from "./styled";
import { theme } from "@styles/theme";

export type ModalWindowProps = {
  show: boolean;
  width?: string;
  height?: string;
  ignoreHeightAndScroll?: boolean;
  onClose?: () => void;
};

export const ModalWindow: FC<ModalWindowProps> = ({
  show = false,
  width,
  height,
  onClose,
  ignoreHeightAndScroll,
  children,
}) =>
  show ? (
    <Overlay>
      <Window
        ignoreHeightAndScroll={ignoreHeightAndScroll}
        width={width}
        height={height}
      >
        <Card>
          <CloseButton onClick={onClose}>
            <Icon type="CloseSmall" size={24} fill={theme.colors.BLUE700} />
          </CloseButton>

          {children}
        </Card>
      </Window>
    </Overlay>
  ) : null;

export default ModalWindow;
