import React, { FC, useState, useContext } from "react";
import { Formik } from "formik";
import { theme, Text } from "@ifgengineering/component-library";
import { FieldStyled, TitleSpacer } from "./styled";
import Icon from "@icon-park/react/es/all";
import { BackContainer, Link } from "../styled";
import { getEmailSchema } from "./validationSchemas";
import { navigate } from "gatsby";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Input,
  FilledButton as Button,
} from "@ifgengineering/component-library";
import { AuthComponentsContext } from "../../../Context";

interface MagicCodeInputProps {
  email: string;
  signupURL?: string;
  onChange: (email: string) => void;
  handleBackButton: () => void;
  handleSuccess: () => void;
}

const GetEmailForm: FC<MagicCodeInputProps> = ({
  email,
  signupURL,
  onChange,
  handleBackButton,
  handleSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { passwordlessStart, logException } = useContext(AuthComponentsContext);

  const startMagicCodeProcess = async (email: string) => {
    setIsLoading(true);

    try {
      await passwordlessStart(email);
    } catch (e: any) {
      if (axios.isAxiosError(e) && e.response && e.response.status !== 404) {
        toast.error("Something went wrong. Try again or contact support");

        logException({
          email,
          tag: "passwordlessStart",
          exception: e as any,
        });

        throw e;
      }
    }

    handleSuccess();

    setIsLoading(false);
  };

  return (
    <div data-testid="otp">
      <Formik
        enableReinitialize
        validationSchema={getEmailSchema}
        initialValues={{ email }}
        onSubmit={({ email }) => {
          startMagicCodeProcess(email);
        }}
      >
        {({ setFieldValue, values, errors, touched, submitForm }) => {
          return (
            <>
              <BackContainer onClick={handleBackButton}>
                <Icon
                  type="ArrowLeft"
                  size={18}
                  theme="outline"
                  fill={theme.colors.BLUE600}
                />
                <Text type="S16" color="BLUE600" fontFamily="Inter">
                  Back
                </Text>
              </BackContainer>
              <br />
              <TitleSpacer>
                <Text type="T32" color="SLATE800" fontFamily="archiasemibold">
                  Login without password
                </Text>
              </TitleSpacer>
              <FieldStyled>
                <Input
                  testId="magic-code-email"
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                    onChange(e.target.value);
                  }}
                  errorMessage={touched.email ? errors.email : undefined}
                  heading="Email"
                />
              </FieldStyled>
              <Button
                testId="login-button"
                type="button"
                height="56px"
                text="Email Login Code"
                color="darkBlue"
                disabled={isLoading}
                onClick={submitForm}
                isLoading={isLoading}
              />
              <div>
                <br />
                <Text type="S16" color="SLATE700">
                  New here?
                </Text>{" "}
                <Link onClick={() => navigate(signupURL || "/auth/signup")}>
                  Sign Up Now
                </Link>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default GetEmailForm;
