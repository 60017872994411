import React, { FC, useEffect, useState, useRef } from "react";
import {
  ErrorContainer,
  Input,
  PasswordInput,
  Text,
  FilledButton as Button,
  theme,
} from "@ifgengineering/component-library";
import { navigate } from "gatsby";
import { Field, Formik } from "formik";
import { useDispatch } from "react-redux";
import {
  Container,
  Link,
  ForgotPassword,
  TextDivider,
  StyledLine,
  OutlineButtonWithImage,
} from "../styled";
import * as Yup from "yup";
import MagicCode from "../MagicCode";
import Icon from "@icon-park/react/es/all";
import { useClientAuthComponents } from "../../../Context";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { SigninFieldType, SigninProps } from "./types";
import GoogleIcon from "../../../../../assets/googleIcon.png";
import AppleIcon from "../../../../../assets/appleIcon.png";
import AppleLogin from "react-apple-login";
import { toast } from "react-toastify";

const ReCaptchaContainer = styled.div`
  width: 100%;
  height: 104px;
`;

const Signin: FC<SigninProps> = ({
  enableMagicCode = true,
  googleLogin,
  appleLogin,
  forgotPasswordURL,
  loginRedirect,
  onSubmit,
  signupURL,
  title,
  errorMessage,
  isLoading,
}) => {
  const { useAnalytics, loginAction, recaptchaClientKey } =
    useClientAuthComponents();

  const [loading, setLoading] = useState(false);
  const [showMagicCodeForm, setShowMagicCodeForm] = useState(false);
  const firePageViewAnalytics = useAnalytics("pageView");
  const recaptchaRef = useRef() as React.MutableRefObject<ReCAPTCHA>;

  const SigninSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Must be at least 8 characters")
      .max(64, "Please enter a password less than 64 characters")
      .required("Password is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    captchaToken: recaptchaClientKey
      ? Yup.string().required("Please verify you are not a robot")
      : Yup.string().nullable(),
  });

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({
        eventName,
      });
    };
    firePageView(`all:login_pageViewed`);
  }, []);

  const fireAnalytics = useAnalytics("login");
  const dispatch = useDispatch();

  const handleSubmit = async (values: {
    email: string;
    password: string;
    captchaToken: string;
  }) => {
    setLoading(true);

    if (onSubmit) {
      onSubmit({
        email: values.email.toLowerCase(),
        password: values.password,
        captchaToken: values.captchaToken,
      });
      (await fireAnalytics)({
        email: values.email.toLowerCase(),
        method: "password",
      });

      setLoading(false);
      return;
    }

    const response = await dispatch(
      loginAction({
        email: values.email.toLowerCase(),
        password: values.password,
      })
    );
    (await fireAnalytics)({
      email: values.email.toLowerCase(),
      method: "password",
    });

    setLoading(false);
    if (response?.payload) navigate(loginRedirect);
  };

  const fireForgotPasswordAnalytics = useAnalytics("forogtPasswordClick");
  const isSocialLoginEnabled = googleLogin?.enable || appleLogin?.enable;

  const regularLoginInput = (
    <Formik
      enableReinitialize
      validationSchema={SigninSchema}
      initialValues={{ email: "", password: "", captchaToken: "" }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, submitForm, errors, touched }) => {
        const isEmailInvalid = errors.email && touched.email;
        const isPasswordInvalid = errors.password && touched.password;
        const isCaptchaTokeInvalid =
          errors.captchaToken && touched.captchaToken;
        const shouldDisableLoginBtn =
          isEmailInvalid ||
          isPasswordInvalid ||
          isCaptchaTokeInvalid ||
          isLoading;

        return (
          <>
            <Text type="T18" color="SLATE800" fontFamily="archiasemibold">
              {title || "Login"}
            </Text>

            {googleLogin?.enable && (
              <OutlineButtonWithImage
                text="Continue with Google"
                image={GoogleIcon}
                testId="google-login-button"
                onClick={googleLogin?.onClick}
                type="button"
              />
            )}
            {appleLogin?.enable && (
              <AppleLogin
                clientId={appleLogin.clientId}
                redirectURI={appleLogin.redirectURI}
                usePopup={true}
                responseMode="form_post"
                responseType="code"
                scope="email"
                render={({ onClick }) => (
                  <OutlineButtonWithImage
                    text="Continue with Apple"
                    image={AppleIcon}
                    testId="apple-login-button"
                    onClick={onClick}
                    type="button"
                  />
                )}
                callback={(data) => {
                  if ("error" in data) {
                    toast.error("Error authenticating with Apple.");
                    return;
                  }
                  if (data) {
                    appleLogin.onClick && appleLogin?.onClick(data);
                  }
                }}
              />
            )}
            {isSocialLoginEnabled && (
              <TextDivider data-testid="txnLiUpdate">
                <StyledLine data-testid="txnLiUpdate-line-1" />
                <Text type="S14" color="SLATE700" testId="txnLiUpdate-update">
                  or Login with Email
                </Text>
                <StyledLine data-testid="txnLiUpdate-line-2" />
              </TextDivider>
            )}
            <Field name="email">
              {({ field, form: { errors, touched } }: SigninFieldType) => (
                <Input
                  {...field}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                  errorMessage={touched.email ? errors.email : undefined}
                  heading="Email"
                  testId="email-input"
                />
              )}
            </Field>
            <Field name="password">
              {({ field, form: { errors, touched } }: SigninFieldType) => (
                <PasswordInput
                  {...field}
                  errorMessage={touched.password ? errors.password : undefined}
                  onChange={(value) => setFieldValue("password", value)}
                  heading="Password"
                  hideBar
                />
              )}
            </Field>
            <ForgotPassword
              onClick={async () => {
                (await fireForgotPasswordAnalytics)();
                navigate(forgotPasswordURL || "/auth/forgot-password");
              }}
            >
              Set or Reset Password
            </ForgotPassword>
            {errorMessage && (
              <ErrorContainer>
                <Icon
                  type="Caution"
                  size={14}
                  fill={theme.colors.ERROR800}
                  theme="filled"
                />
                <Text type="P14" color="ERROR800">
                  {errorMessage}
                </Text>
              </ErrorContainer>
            )}
            <Field name="captchaToken">
              {({ form: { errors, touched } }: SigninFieldType) => (
                <ReCaptchaContainer data-testid="recaptcha-container">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={`${recaptchaClientKey}`}
                    size="normal"
                    onChange={(value) => setFieldValue("captchaToken", value)}
                  />
                  {touched.captchaToken && errors.captchaToken && (
                    <ErrorContainer>
                      <Icon
                        type="Caution"
                        size={14}
                        fill={theme.colors.ERROR800}
                        theme="filled"
                      />
                      <Text type="P14" color="ERROR800">
                        {errors.captchaToken}
                      </Text>
                    </ErrorContainer>
                  )}
                </ReCaptchaContainer>
              )}
            </Field>
            <Button
              testId="login-button"
              height="56px"
              text="Login"
              color="darkBlue"
              onClick={submitForm}
              isLoading={isLoading || loading}
              disabled={shouldDisableLoginBtn}
            />
            {enableMagicCode && (
              <Button
                testId="magic-login-button"
                type="button"
                height="56px"
                text="Login without password"
                color="lightBlue"
                textColor="BLUE600"
                onClick={() => setShowMagicCodeForm(true)}
                customBackgroundColors={{
                  default: theme.colors.INDIGO100,
                  hover: theme.colors.INDIGO500,
                  active: theme.colors.INDIGO900,
                  selected: theme.colors.INDIGO500,
                }}
              />
            )}
            <div>
              <br />
              <Text type="S16" color="SLATE700">
                New here?
              </Text>{" "}
              <Link onClick={() => navigate(signupURL || "/auth/signup")}>
                Sign Up Now
              </Link>
            </div>
          </>
        );
      }}
    </Formik>
  );

  return (
    <Container>
      {showMagicCodeForm ? (
        <MagicCode
          handleCancel={() => setShowMagicCodeForm(false)}
          signupURL={signupURL}
          loginRedirect={loginRedirect}
        />
      ) : (
        regularLoginInput
      )}
    </Container>
  );
};

export default Signin;
