import React, { useState, useRef, useEffect } from "react";
import { DateInputWrapper } from "./styled";
import { validateNumerical } from "./utils/validation";
import { parse } from "date-fns";
import InputWrapper from "../InputWrapper";
import Text from "../Text";
import GenericInput from "../GenericInput";
import formatToUTC from "./utils/formatToUTC";

type Props = {
  heading?: string;
  errorMessage?: string;
  onChange: (date: Date) => void;
  value?: Date | null;
};

const DateInput: React.FC<Props> = ({
  heading,
  value,
  onChange,
  errorMessage,
}) => {
  const dayInput = useRef<HTMLInputElement>(null);
  const monthInput = useRef<HTMLInputElement>(null);
  const yearInput = useRef<HTMLInputElement>(null);

  const [day, setDay] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [isFocused, setIsFocused] = useState<string>("");

  useEffect(() => {
    // Sets date passed down from value
    if (value) {
      const splitDate = formatToUTC(value, "yyyy-MM-dd").split("-");
      setYear(splitDate[0]);
      setMonth(splitDate[1]);
      setDay(splitDate[2]);
    }
  }, []);

  useEffect(() => {
    if (day && month && year) {
      onChange(parse(`${year}-${month}-${day}`, "yyyy-MM-dd", new Date()));
    } else {
      onChange(new Date());
    }
  }, [day, month, year]);

  const onChangeYear = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement;
    if (value.length < 5) setYear(value);
  };

  const onChangeMonth = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement;
    if ((parseInt(value) < 13 || value === "") && value.length < 3)
      setMonth(value);
    if (value.length === 2) yearInput.current && yearInput.current.focus();
  };

  const onChangeDay = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement;
    if ((parseInt(value) < 32 || value === "") && value.length < 3)
      setDay(value);
    if (value.length === 2) monthInput.current && monthInput.current.focus();
  };

  const keyDownDay = (
    e: React.KeyboardEvent<HTMLInputElement>,
    value: string
  ) => {
    validateNumerical(e);
    e.key === "ArrowRight" &&
      !value &&
      monthInput.current &&
      monthInput.current.focus();
  };

  const keyDownMonth = (
    e: React.KeyboardEvent<HTMLInputElement>,
    value: string
  ) => {
    validateNumerical(e);
    if (e.key === "ArrowRight" && !value)
      yearInput.current && yearInput.current.focus();
    if ((e.key === "Backspace" || e.key === "ArrowLeft") && !value)
      dayInput.current && dayInput.current.focus();
  };

  const keyDownYear = (
    e: React.KeyboardEvent<HTMLInputElement>,
    value: string
  ) => {
    validateNumerical(e);
    (e.key === "Backspace" || e.key === "ArrowLeft") &&
      !value &&
      monthInput.current &&
      monthInput.current.focus();
  };

  const onBlurAction = () => {
    setIsFocused("");
  };

  return (
    <InputWrapper heading={heading} errorMessage={errorMessage}>
      <DateInputWrapper>
        <div>
          <Text type={"P14"} color={"SLATE700"}>
            Day
          </Text>
          <GenericInput
            width="60px"
            focused={isFocused === "day"}
            data-testid="day-input"
            ref={dayInput}
            onFocus={() => setIsFocused("day")}
            onBlur={onBlurAction}
            type="number"
            placeholder="DD"
            onChange={onChangeDay}
            onKeyDown={(e) => keyDownDay(e, day)}
            value={day}
          />
        </div>
        <div>
          <Text type={"P14"} color={"SLATE700"}>
            Month
          </Text>
          <GenericInput
            width="70px"
            focused={isFocused === "month"}
            data-testid="month-input"
            ref={monthInput}
            onFocus={() => setIsFocused("month")}
            onBlur={onBlurAction}
            type="number"
            placeholder="MM"
            onChange={onChangeMonth}
            onKeyDown={(e) => keyDownMonth(e, month)}
            value={month}
          />
        </div>
        <div>
          <Text type={"P14"} color={"SLATE700"}>
            Year
          </Text>
          <GenericInput
            width="76px"
            focused={isFocused === "year"}
            data-testid="year-input"
            ref={yearInput}
            onFocus={() => setIsFocused("year")}
            onBlur={onBlurAction}
            type="number"
            placeholder="YYYY"
            onChange={onChangeYear}
            onKeyDown={(e) => keyDownYear(e, year)}
            value={year}
          />
        </div>
      </DateInputWrapper>
    </InputWrapper>
  );
};

export default DateInput;
