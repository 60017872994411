import { AxiosPromise, AxiosStatic } from "axios";
import { GetQuizOutput, PostQuizInput, PostQuizOutput } from "../types/quiz";

export const postQuiz =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (quiz: PostQuizInput[]): AxiosPromise<PostQuizOutput> =>
    await appAxios.post(`${serverURL}/quiz`, quiz, {
      withCredentials: true,
    });

export const getQuiz =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (): AxiosPromise<GetQuizOutput> =>
    await appAxios.get(`${serverURL}/quiz`, {
      withCredentials: true,
    });
