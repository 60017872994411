import React from "react";
import Icon from "@icon-park/react/es/all";

import Container from "./styled/Container";
import TextArea from "./styled/TextArea";
import DisabledText from "./styled/DisabledText";
import ErrorContainer from "./styled/ErrorContainer";
import Text from "../Text";
import GenericInput from "../GenericInput";
import { theme } from "../../styles";

export type Props = {
  value: string;
  testId?: string;
  name?: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errorMessage?: string;
  placeholder?: string;
  heading?: string;
  type?: string;
  disabled?: boolean;
  isLong?: boolean;
  height?: string;
};

const Input: React.FC<Props> = ({
  placeholder,
  value,
  testId,
  name,
  onChange,
  onBlur,
  onFocus,
  disabled,
  isLong,
  errorMessage = "",
  type = "text",
  heading,
  height,
  ...props
}) => {
  const InputComponent: React.ElementType = isLong ? TextArea : GenericInput;
  return (
    <Container height={height}>
      {heading && (
        <Text type="S16" color="SLATE800">
          {heading}
        </Text>
      )}
      {disabled ? (
        <DisabledText>{value}</DisabledText>
      ) : (
        <InputComponent
          data-testid={testId}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          hasError={errorMessage}
          isLong={isLong}
          name={name}
          {...props}
        />
      )}
      {errorMessage && (
        <ErrorContainer>
          <Icon
            type="Caution"
            size={14}
            fill={theme.colors.ERROR800}
            theme="filled"
          />
          <Text type="P14" color="ERROR800">
            {errorMessage}
          </Text>
        </ErrorContainer>
      )}
    </Container>
  );
};

export default Input;
