import React from "react";
import { MenuTemplateItem } from "../types";
import Link from "./Link";
import styled from "styled-components";

interface MenuItemsProps extends Partial<MenuTemplateItem> {
  items: MenuTemplateItem[];
  gap?: number;
}

const MenuItemsContainer = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap + "px"};
`;

const MenuItems: React.FC<MenuItemsProps> = ({
  items,
  gap,
  ...menuItemProps
}) => (
  <MenuItemsContainer gap={gap}>
    {items.map(({ tab, label, selected, labelTag, href, onClick }) => (
      <Link
        tab={tab}
        label={label}
        selected={selected}
        onClick={onClick}
        labelTag={labelTag}
        key={label}
        href={href}
        {...menuItemProps}
      />
    ))}
  </MenuItemsContainer>
);

export default MenuItems;
