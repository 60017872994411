import { PayloadAction } from "@reduxjs/toolkit";
import { signInAsync, signOutAsync } from "../auth/auth.actions";
import {
  fetchCommitmentPreviewAPI,
  clearCommitmentPreview,
} from "@state/commitmentPreview/commitmentPreview.actions";
import { CommitmentPreviewPayload } from "@ifgengineering/client-invest-sdk";

export interface CommitmentPreviewState {
  neverLoaded: boolean;
  loading: boolean;
  data?: CommitmentPreviewPayload;
}

export const INITIAL_STATE: CommitmentPreviewState = {
  neverLoaded: true,
  loading: false,
  data: undefined,
};

const commitmentPreviewReducer = (
  state = INITIAL_STATE,
  action: PayloadAction<CommitmentPreviewPayload>
): CommitmentPreviewState => {
  switch (action.type) {
    case signInAsync.fulfilled.type:
    case signOutAsync.fulfilled.type:
      return {
        ...INITIAL_STATE,
      };
    case fetchCommitmentPreviewAPI.pending.type: {
      return {
        ...state,
        loading: true,
      };
    }
    case fetchCommitmentPreviewAPI.rejected.type: {
      return {
        ...state,
        neverLoaded: true,
        loading: false,
      };
    }
    case fetchCommitmentPreviewAPI.fulfilled.type: {
      return {
        ...state,
        neverLoaded: false,
        loading: false,
        data: {
          ...state.data,
          ...(action.payload.errorMessage
            ? {
                errorMessage: action.payload.errorMessage,
              }
            : {
                errorMessage: null,
                ...action.payload,
              }),
          instalmentPlanBreakdown: {
            ...(action.payload.errorMessage
              ? state.data?.instalmentPlanBreakdown
              : {
                  ...state.data?.instalmentPlanBreakdown,
                  ...action.payload.instalmentPlanBreakdown,
                }),
          },
        },
      };
    }
    case clearCommitmentPreview.type: {
      return INITIAL_STATE;
    }
    default:
      return INITIAL_STATE;
  }
};

export default commitmentPreviewReducer;
