import React from "react";
import { ColorType, Text } from "@ifgengineering/component-library";
import { ChipsContainer, StyledChip } from "./styled";

interface ChipProps {
  text: string;
  textColor: ColorType;
  bgColor: string;
  borderRadius?: number;
}

const Chip: React.FC<ChipProps> = ({
  text,
  textColor,
  bgColor,
  borderRadius = 48,
}) => {
  return (
    <StyledChip bgColor={bgColor} borderRadius={borderRadius}>
      <Text type="S16" color={textColor}>
        {text}
      </Text>
    </StyledChip>
  );
};

interface ChipsProps extends ChipProps {
  items: Array<string>;
}

export const Chips: React.FC<Omit<ChipsProps, "text">> = ({
  items,
  bgColor,
  textColor,
  borderRadius,
}) => {
  return (
    <ChipsContainer>
      {items.map((item, idx) => (
        <Chip
          key={idx}
          text={item}
          textColor={textColor}
          bgColor={bgColor}
          borderRadius={borderRadius}
        />
      ))}
    </ChipsContainer>
  );
};
