import { Investor } from "@ifgengineering/client-invest-sdk";
import { RootState } from "../rootReducer";
import { differenceInDays } from "date-fns";

export const verifyInvestorStatusSelector = (state: RootState): boolean => {
  const investor = state.investor?.data;
  const isOnboardingCompleted = investor?.onboardingStatus === "COMPLETED";

  const profileType = investor?.accreditationProfile?.type;
  const isHighNetWorthInvestor = profileType === "HIGH_NET_WORTH";
  const isSelfCertifiedInvestor = profileType === "SELF_CERTIFIED";

  const investorSignedDate = investor?.accreditationProfile?.signedAt;

  const shouldRecertify = investorSignedDate
    ? differenceInDays(new Date(), new Date(investorSignedDate)) > 365
    : false;

  return (
    (isHighNetWorthInvestor || isSelfCertifiedInvestor) &&
    isOnboardingCompleted &&
    shouldRecertify
  );
};

export const isOnboardingCompleteSelector = (state: RootState): boolean =>
  state?.investor?.data?.onboardingStatus === "COMPLETED";

export const investorSelector = (state: RootState): Partial<Investor> | null =>
  state.investor.data || null;

export const isLoadingInvestorSelector = (state: RootState): boolean =>
  state.investor.loading;

export const neverLoadedInvestorSelector = (state: RootState): boolean =>
  state.investor.neverLoaded;

export const investorBankDetailsSelector = (
  state: RootState
): Investor["bankDetails"] => state.investor.data?.bankDetails;

export const hasKYCStartedSelector = (state: RootState): boolean =>
  !!state.investor.data?.kycProfile?.status &&
  state.investor.data.kycProfile.status !== "NOT_STARTED";

export const hasKYCFinishedSelector = (state: RootState): boolean =>
  !!state.investor.data?.kycProfile?.status &&
  state.investor.data.kycProfile.status === "APPROVED";
