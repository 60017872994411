import {
  TNotification,
  TUpdateNotificationPayload,
} from "@ifgengineering/client-notifications-sdk";
import {
  fetchNotificationsThunk,
  updateNotificationsAction,
} from "./notification.actions";

export type NotificationState = {
  neverLoadedNotifications: boolean;
  loading: boolean;
  data: TNotification[];
};

export const INITIAL_STATE: NotificationState = {
  neverLoadedNotifications: true,
  loading: false,
  data: [],
};

const notificationReducer = (
  state = INITIAL_STATE,
  action: any
): NotificationState => {
  const { payload } = action;

  switch (action.type) {
    case fetchNotificationsThunk.rejected.type:
      return {
        ...INITIAL_STATE,
      };
    case fetchNotificationsThunk.pending.type:
      return {
        ...state,
        loading: true,
      };
    case fetchNotificationsThunk.fulfilled.type:
      return {
        neverLoadedNotifications: false,
        loading: false,
        data: payload,
      };

    case updateNotificationsAction.type:
      return {
        ...state,
        data: state.data.map((notification) => {
          const notificationToUpdate = payload.find(
            (notificationRead: TUpdateNotificationPayload) =>
              notificationRead.id === notification.id
          );

          if (!notificationToUpdate) {
            return notification;
          }

          return {
            ...notification,
            status: "READ",
          };
        }),
      };
    default:
      return state;
  }
};

export default notificationReducer;
