import { init } from "@ifgengineering/client-analytics";
import {
  APP_NAME,
  GOOGLE_ADS_ID,
  GOOGLE_ADS_SIGNUP_TAG_ID,
  MIXPANEL_TOKEN,
} from "gatsby-env-variables";

const initialisedAnalytics = init({
  APP_NAME,
  GOOGLE_ADS_ID,
  GOOGLE_ADS_SIGNUP_TAG_ID,
  MIXPANEL_TOKEN,
});

const uninitializedAnalytics =
  (s: string): any =>
  (a: any): void => {
    return;
  };

const getAnalytics = () => {
  // It prevents crashes when building the SSR with Gatsby.
  if (typeof window === "undefined") {
    return initialisedAnalytics;
  }

  return window.DO_NOT_TRACK === true
    ? uninitializedAnalytics
    : initialisedAnalytics;
};

const useAnalytics = getAnalytics();

export default useAnalytics;
