import instalmentPlanTextFormatter from "./instalmentPlanTextFormatter";
import { Commitment, NextDueType } from "../types/Commitment";
import { CurrencyEnumType } from "../types";
import initializeUseCurrency from "./initializeUseCurrency";

export class UserCommitment {
  commitment: Commitment;
  toCurrency: (amount: number) => string;
  constructor({ commitment }: { commitment: Commitment }) {
    this.commitment = commitment;
    this.toCurrency = this.initUseCurrency().toCurrency;
  }

  protected getTime(date: string | number): number {
    return new Date(date).getTime();
  }

  protected orderByDueDate(
    payments: NextDueType[],
    orderBy: "asc" | "desc" = "asc"
  ): NextDueType[] {
    return [...payments].sort(({ dueDate: a }, { dueDate: b }) => {
      if (orderBy === "desc") return this.getTime(b) - this.getTime(a);
      return this.getTime(a) - this.getTime(b);
    });
  }

  get isOneOffInvestment(): boolean {
    return !this.commitment.instalmentPlan ? true : false;
  }

  private totalConfirmedInstalmentPayments(): number {
    const { instalmentPaymentSet } = this.commitment;
    return instalmentPaymentSet.paymentsConfirmed.reduce(
      (acc: number, item) => acc + (item.amountPaid || 0),
      0
    );
  }

  private totalConfirmedOneOffPayments(): number {
    const { oneOffPaymentSet } = this.commitment;

    return oneOffPaymentSet.paymentsConfirmed.reduce(
      (acc: number, item) => acc + (item.amountPaid || 0),
      0
    );
  }

  get totalCommitment(): number {
    if (!this.commitment) return 0;

    return (
      this.totalConfirmedInstalmentPayments() +
      this.totalConfirmedOneOffPayments()
    );
  }

  get totalTransferred(): number {
    const {
      instalmentPaymentSet: { paymentsConfirmed },
    } = this.commitment;
    const totalAmountPaid =
      paymentsConfirmed?.reduce((acc, curr) => {
        return acc + (curr.amountPaid || 0);
      }, 0) || 0;
    return totalAmountPaid;
  }

  private initUseCurrency() {
    return initializeUseCurrency(this.commitment.currency);
  }

  get instalmentBreakdown(): string | undefined {
    return instalmentPlanTextFormatter({
      amount: this.commitment.instalmentPlanAmount || 0,
      instalmentName: this.commitment.instalmentPlan?.name,
      maxPaymentMonths: this.commitment.maxPaymentMonths,
      toCurrency: this.toCurrency,
    });
  }

  get instalmentPlanProgress(): string {
    const { paymentsPending, paymentsConfirmed, paymentsDue } =
      this.commitment.instalmentPaymentSet;
    const totalPending =
      paymentsPending.length + paymentsConfirmed.length + paymentsDue.length;

    return `${this.commitment.instalmentPaymentSet.paymentsConfirmed.length} / ${totalPending}`;
  }

  get instalmentPlan(): "monthly" | "quarterly" | null {
    const hasInstalmentPlan = !!this.commitment?.instalmentPlan?.name;
    const planName =
      this?.commitment?.instalmentPlan?.name === "MONTHLY"
        ? "monthly"
        : "quarterly";
    const plan = hasInstalmentPlan ? planName : null;
    return plan;
  }

  get currency(): CurrencyEnumType {
    return this.commitment.currency;
  }

  private getNextDue(): NextDueType[] {
    const { status, instalmentPaymentSet, oneOffPaymentSet } = this.commitment;
    if (status === "CANCELLED") return [];

    let allDue: NextDueType[] = instalmentPaymentSet.paymentsDue.map(
      (item) => ({
        amount: item.amountDue,
        dueDate: item.dueAt,
        title: "Recurring Amount",
        type: "Monthly Instalment",
      })
    );

    if (oneOffPaymentSet.paymentDue) {
      allDue = [
        ...allDue,
        {
          amount: oneOffPaymentSet.paymentDue.amountDue,
          dueDate: oneOffPaymentSet.paymentDue.dueAt,
          title: "One-Off Transfer",
          type: "One-Off Transfer",
        },
      ];
    }

    const orderedDueItems = this.orderByDueDate(allDue);

    return orderedDueItems;
  }

  get nextDue(): NextDueType[] {
    return this.getNextDue();
  }
}
