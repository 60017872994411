import React from "react";
import PhoneInputComponent from "react-phone-number-input";
import "./PhoneInput.styles.scss";

type Props = {
  onChange: (phone: any) => void;
  value: any;
  placeholder: string;
};

const PhoneInput: React.FC<Props> = ({ onChange, value, placeholder }) => (
  // @ts-expect-error this component in its current version will throw a type definition issue. We need to upgrade to React 18 and the upgrade it
  <PhoneInputComponent
    data-testid="phone-input"
    international
    value={value}
    placeholder={placeholder}
    defaultCountry="GB"
    onChange={onChange}
  />
);

export default PhoneInput;
