import PortfolioActionType from "./portfolio.types";
import { fetchPortfolioAPI } from "./portfolio.actions";
import { signInAsync, signOutAsync } from "../auth/auth.actions";
import { Portfolio } from "@ifgengineering/client-invest-sdk";

export interface PortfolioState {
  neverLoaded: boolean;
  loading: boolean;
  data: Portfolio[];
  stripeCheckoutSessionId: string;
}

export const INITIAL_STATE: PortfolioState = {
  neverLoaded: true,
  loading: false,
  stripeCheckoutSessionId: "",
  data: [],
};

const portfolioReducer = (
  state = INITIAL_STATE,
  action: any
): PortfolioState => {
  switch (action.type) {
    case signInAsync.fulfilled.type:
    case signOutAsync.fulfilled.type:
      return {
        ...INITIAL_STATE,
      };
    case fetchPortfolioAPI.pending.type:
      return {
        ...state,
        loading: true,
      };
    case fetchPortfolioAPI.fulfilled.type: {
      return {
        ...state,
        neverLoaded: false,
        loading: false,
        data: action.payload,
      };
    }
    case PortfolioActionType.CLEAR_ERROR_AND_RESULT:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default portfolioReducer;
