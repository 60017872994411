import styled from "styled-components";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";

export const DesktopLogo = styled.img`
  width: 93.78px;
  height: 69.28px;
  align-self: flex-start;
  position: absolute;
  top: -150px;
  left: -30px;
`;

export const MobileLogo = styled.img`
  width: 51.38px;
  height: 48px;
  align-self: flex-start;
`;

export const PromoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 440px;
  position: relative;

  @media ${MEDIA_QUERIES.TAB_PORT} {
    width: 100%;
    padding: 32px 24px;
    align-items: baseline;
  }
`;

export const CharityLogo = styled.img`
  height: 127.34px;

  @media ${MEDIA_QUERIES.TAB_PORT} {
    height: 90px;
  }
`;

export const Spacer = styled.div`
  padding-top: 24px;
`;
