import { theme } from "@ifgengineering/component-library";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;
`;

export const IconContainer = styled.div<{ highlight: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 21px;
  cursor: pointer;
  white-space: nowrap;
  background-color: ${({ highlight }) =>
    highlight ? theme.colors.WHITE : "transparent"};
`;

export const MainIcon = styled.div`
  width: 32px;
  height: 32px;
`;

export const NotificationListContainer = styled.div`
  position: absolute;
  min-width: 200px;
  top: calc(100% + 0px);
  height: 70vh;
  width: 400px;
  z-index: 10;
  background-color: ${theme.colors.WHITE};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 8px 8px;
`;

export const NotificationScrollArea = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;
`;

export const NotificationBadgeHeader = styled.div`
  padding: 10px 20px;
`;

export const NotificationBadge = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  position: absolute;
  top: 20px;
  left: 40px;
  background-color: ${theme.colors.GREEN400};
`;

export const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  position: absolute;
  width: 100%;
`;
