import styled from "styled-components";
import Icon from "@icon-park/react/es/all";

export const NavChildrenContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;
`;

export const FloatingMenuContainer = styled.div<{ isOpen?: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 21px;
  cursor: pointer;
  white-space: nowrap;
  min-width: 140px;
  background-color: ${({ isOpen }) => isOpen && "white"};
`;

export const MenuContainer = styled.div`
  position: absolute;
  min-width: 200px;
  right: 0;
  top: calc(100% + 0px);
`;

export const HowItWorksButton = styled.button`
  display: flex;
  gap: 4px;
  white-space: nowrap;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavigationBarContainer = styled.div`
  z-index: 10;
`;
