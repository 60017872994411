import React, { FC } from "react";
import { ModalWindow } from "@components/ModalWindow";
import {
  FilledButton,
  Text,
  MEDIA_QUERIES,
} from "@ifgengineering/component-library";
import styled from "styled-components";

export type TutorialModalProps = {
  show: boolean;
  onClose?: () => void;
  title: string;
  description: string;
  buttonText: string;
  videoUrl: string;
};

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  position: relative;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 360px;
  @media ${MEDIA_QUERIES.TAB_LAND} {
    height: 300px;
  }

  @media ${MEDIA_QUERIES.TAB_PORT} {
    height: 250px;
  }
`;

const TutorialModal: FC<TutorialModalProps> = ({
  show,
  onClose,
  title,
  description,
  buttonText,
  videoUrl,
}) => (
  <ModalWindow show={show} onClose={onClose}>
    <ContentWrapper>
      <StyledIframe
        src={videoUrl}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <Text type="T32" fontFamily="archiasemibold" color="SLATE800">
        {title}
      </Text>
      <Text type="P18" color="SLATE700">
        {description}
      </Text>
      <FilledButton
        testId="tutorial-modal-close-button"
        width="202px"
        color="blue"
        text={buttonText}
        onClick={onClose}
      />
    </ContentWrapper>
  </ModalWindow>
);

export default TutorialModal;
