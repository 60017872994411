import { Deal } from "../types";

export type SupportedPlanType =
  | "monthly-only"
  | "quarterly-only"
  | "oneOff-only"
  | "all";

export const getSupportedPlans = (deal: Deal): SupportedPlanType => {
  const plans = ["MONTHLY", "QUARTERLY"];

  const isOnlyOneInstalmentPlan = deal?.instalmentPlans.length === 1;
  const supportsAllPlans = plans.every((currVal) =>
    deal?.instalmentPlans.some(({ name }) => name === currVal)
  );
  const supportsOnlyMonthlyPlan =
    isOnlyOneInstalmentPlan && deal.instalmentPlans[0].name === "MONTHLY";
  const supportsOnlyQuarterlyPlan =
    isOnlyOneInstalmentPlan && deal.instalmentPlans[0].name === "QUARTERLY";

  switch (true) {
    case supportsOnlyMonthlyPlan:
      return "monthly-only";
    case supportsOnlyQuarterlyPlan:
      return "quarterly-only";
    case supportsAllPlans:
      return "all";
    default:
      return "oneOff-only";
  }
};
