import { DiscoverPage, WealthMainPageType } from "../types";
import { IconType } from "@icon-park/react/lib/all";

export const iconMap: Partial<Record<DiscoverPage, IconType>> = {
  compare: "Funds",
  shortlist: "Funds",
  track: "MonitorTwo",
  wills: "Gift",
  zakat: "Division",
  cur8: "Rocket",
  courses: "CarouselVideo",
  profile: "Avatar",
  hub: "IdCard",
  beginner: "Funds",
  settings: "Setting",
  logout: "Logout",
  help: "Help",
  tools: "TriangleRoundRectangle",
};

export const pageIconMap: Record<WealthMainPageType, IconType> = {
  Compare: "Funds",
  Track: "MonitorTwo",
  Wills: "Gift",
  Zakat: "Division",
  Invest: "Rocket",
  Courses: "CarouselVideo",
  Hub: "IdCard",
  Settings: "SettingTwo",
  Help: "Help",
  Tools: "TriangleRoundRectangle",
};
