import React, { FC, useState } from "react";
import styled from "styled-components";
import { theme, Text, MEDIA_QUERIES } from "@ifgengineering/component-library";
import Icon from "@icon-park/react/es/all";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: ${theme.colors.SUCCESS200};

  @media ${MEDIA_QUERIES.TAB_PORT} {
    flex-direction: column-reverse;
    padding-bottom: 20px;
  }
`;

const TextWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
`;

const StyledIcon = styled(Icon)`
  @media ${MEDIA_QUERIES.TAB_PORT} {
    align-self: flex-end;
    padding: 8px 0;
  }
`;

export const NewLoginBanner: FC = () => {
  const [isHidden, setIsHidden] = useState<boolean>(false);
  if (isHidden) return null;

  return (
    <Container>
      <div />
      <TextWrapper>
        <Text type="S16" color="SLATE900">
          Great news! We have combined IFG Wealth and Cur8 into one single
          application.
        </Text>
        <Text type="P16" color="SLATE900">
          If you have two credentials login with your Cur8 one going forward. If
          you have one credential (IFG Wealth or Cur8), continue to login with
          this.
        </Text>
      </TextWrapper>
      <StyledIcon
        type="close-one"
        size={20}
        fill={theme.colors.SUCCESS800}
        onClick={() => setIsHidden(true)}
      />
    </Container>
  );
};

export default NewLoginBanner;
