import { AxiosPromise, AxiosResponse, AxiosStatic } from "axios";
import {
  CommitmentInput,
  CommitmentPreviewPayload,
  Commitment,
  CommitmentWithError,
  GetCommitmentPreviewInput,
  Payment,
  PaymentInput,
  PaymentTimelineActivityEvent,
  GetCommitmentsInput,
} from "../types/Commitment";
import { UserCommitment } from "../helpers";

export const postCommitment =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (commitment: CommitmentInput): AxiosPromise<Commitment> =>
    await appAxios.post(`${serverURL}/commitments-v2`, commitment, {
      withCredentials: true,
    });

export const getCommitments =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (data?: GetCommitmentsInput): AxiosPromise<Commitment[]> => {
    const commitmentUrl = `${serverURL}/commitments-v2`;
    const dealCommitmentUrl = `${commitmentUrl}?dealId=${data?.dealId}`;
    const url = data?.dealId ? dealCommitmentUrl : commitmentUrl;
    return await appAxios.get(url, {
      withCredentials: true,
    });
  };

export const getPaymentHistories =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (paymentId: string): AxiosPromise<PaymentTimelineActivityEvent[]> =>
    await appAxios.get(
      `${serverURL}/commitments-v2/payments/${paymentId}/timeline`,
      {
        withCredentials: true,
      }
    );

export const postPayment =
  (serverURL: string, appAxios: AxiosStatic) =>
  async ({
    commitmentId,
    paymentInput,
  }: {
    commitmentId: string;
    paymentInput: PaymentInput;
  }): AxiosPromise<Payment> =>
    await appAxios.post(
      `${serverURL}/commitments-v2/${commitmentId}/payments`,
      paymentInput,
      {
        withCredentials: true,
      }
    );

export const getCommitmentPreview =
  (serverURL: string, appAxios: AxiosStatic) =>
  async ({
    commitmentId,
    amount,
    instalmentPlan,
  }: GetCommitmentPreviewInput): Promise<CommitmentPreviewPayload> => {
    const previewUrl = `${serverURL}/commitments-v2/${commitmentId}/preview`;
    const withPlanUrl = `${previewUrl}?instalmentPlanAmount=${amount}&instalmentPlan=${instalmentPlan}`;
    const withoutPlanUrl = `${previewUrl}?oneOffAmount=${amount}`;
    const url = instalmentPlan ? withPlanUrl : withoutPlanUrl;

    const response: AxiosResponse<CommitmentWithError> = await appAxios.get(
      url,
      {
        withCredentials: true,
      }
    );

    const { data } = response;

    const commitment = new UserCommitment({
      commitment: data,
    });

    return {
      commitmentId,
      amount: data.instalmentPlanAmount || 0,
      ...(instalmentPlan && {
        instalmentPlanBreakdown: {
          [instalmentPlan]: commitment.instalmentBreakdown,
        },
      }),
      ...(data.error ? { errorMessage: data.error.message } : {}),
    };
  };
