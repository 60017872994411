import { CurrencyEnumType } from "../types";

export const moneyfy =
  (
    currency: CurrencyEnumType,
    config?: { locales?: Intl.LocalesArgument; noDecimals?: boolean }
  ) =>
  (val?: number): string => {
    const value = val || 0;
    const decimals = config?.noDecimals ? 0 : undefined;
    const locales = config?.locales ?? "en-US";
    return value.toLocaleString(locales, {
      style: "currency",
      currency,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  };
