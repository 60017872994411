import * as Sentry from "@sentry/browser";
import store from "../../state/store";

interface TrackedRequestInput {
  tag?: string;
  exception: any;
  message?: string;
  // Use scope Email for exceptions before user login.
  // Like the login itself or reset password.
  scopeEmail?: string;
}

export const logException = ({
  tag,
  exception,
  message,
  scopeEmail,
}: TrackedRequestInput): void => {
  const state = store.store.getState();
  const { email, id } = state.auth;

  Sentry.setUser({ email: scopeEmail || email, id });

  if (tag) {
    Sentry.setTag("request", tag);
  }

  if (message) {
    Sentry.captureMessage(message);
  }

  Sentry.captureException(exception);
};
