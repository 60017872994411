import { AxiosPromise, AxiosStatic } from "axios";
import {
  Deal,
  GetDealsParams,
  ReturnCalculatorInput,
  ReturnCalculatorResponse,
} from "../types/Deal";

export const getDeal =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (dealId: string): AxiosPromise<Deal> =>
    await appAxios.get(`${serverURL}/deals/${dealId}`, {
      withCredentials: true,
    });

export const getReturnCalculator =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (body: ReturnCalculatorInput): AxiosPromise<ReturnCalculatorResponse> =>
    await appAxios.post(`${serverURL}/deals/calculator/returns`, body, {
      withCredentials: true,
    });

export const getDeals =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (params?: GetDealsParams): AxiosPromise<Deal[]> =>
    await appAxios.get(`${serverURL}/deals`, {
      ...(params
        ? {
            params: {
              ...(params.statuses ? { status: params.statuses } : {}),
              ...(params.pageSize ? { pageSize: params.pageSize } : {}),
              ...(params.sortBy ? { sortBy: params.sortBy } : {}),
            },
          }
        : {}),
      withCredentials: true,
    });
