import React, { FC, useEffect, useState, useContext } from "react";
import { navigate } from "gatsby";
import { FilledButton, Text } from "@ifgengineering/component-library";
import { Container } from "../styled";
import { useLocation } from "@reach/router";
import * as queryString from "query-string";
import { AuthComponentsContext } from "../../../Context";

interface VerifyEmailProps {
  loginURL?: string;
  verifyEmailCallback?: ({
    email,
    token,
  }: {
    email: string;
    token: string;
  }) => Promise<void>;
}

const VerifyEmail: FC<VerifyEmailProps> = ({
  loginURL,
  verifyEmailCallback,
}) => {
  const location = useLocation();
  const { email, token } = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { verifyEmail } = useContext(AuthComponentsContext);

  const verifyFunction = verifyEmailCallback || verifyEmail;

  const verify = async () => {
    setError("");
    setIsLoading(true);
    try {
      if (
        email &&
        token &&
        typeof email === "string" &&
        typeof token === "string"
      ) {
        await verifyFunction({ email, token });
      } else {
        setError("There was a problem verifying your email");
      }
    } catch (e) {
      setError("There was a problem verifying your email");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    verify();
  }, []);

  return (
    <Container>
      <>
        {!error && (
          <>
            <Text type={"T48"} color="SLATE800" fontFamily="archialight">
              Email verified
            </Text>
            <Text type={"T24"} color="SLATE800" fontFamily="archialight">
              Your email has been verified successfully
            </Text>
          </>
        )}
        {error && (
          <Text type={"T24"} color="ERROR500" fontFamily="archialight">
            {error}
          </Text>
        )}
        <FilledButton
          type="submit"
          height="56px"
          text="Login"
          color="darkBlue"
          isLoading={isLoading}
          onClick={() => navigate(loginURL || "/auth/login")}
        />
      </>
    </Container>
  );
};

export default VerifyEmail;
