export const instalmentPlanTextFormatter = ({
  amount,
  instalmentName,
  maxPaymentMonths,
  toCurrency,
}: {
  amount: number;
  instalmentName?: string;
  maxPaymentMonths: number | null | undefined;
  toCurrency: (amount: number) => string;
}): string | undefined => {
  if (!instalmentName) return;

  const monthlyDivisor = maxPaymentMonths || 12;
  const quarterlyDivisor = monthlyDivisor / 3;

  const divisors: Record<string, number> = {
    MONTHLY: Math.round(monthlyDivisor),
    QUARTERLY: Math.round(quarterlyDivisor),
  };
  const instalmentAmount = toCurrency(amount / divisors[instalmentName]);
  const instInterval = instalmentName === "QUARTERLY" ? "quarter" : "month";
  return `${instalmentAmount}/${instInterval}`;
};

export default instalmentPlanTextFormatter;
