type PlatformNames = "iOS" | "android" | "desktop";

const getPlatformName = (): PlatformNames => {
  const isIOS =
    typeof window !== "undefined" &&
    /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isAndroid =
    typeof window !== "undefined" && /Android/i.test(navigator.userAgent);

  if (isIOS) {
    return "iOS";
  }

  if (isAndroid) {
    return "android";
  }

  return "desktop";
};

export default getPlatformName;
