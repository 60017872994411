import { createGlobalStyle } from "styled-components";
import { tailwindReset } from "./tailwind-css-reset";

export const GlobalStyle = createGlobalStyle`
  ${tailwindReset}
  html {
    font-family: "Inter", sans-serif;
  }
  .Toastify {
    height: 0 !important;
  }
  .Toastify__toast-container {
    overflow-y: auto !important;
    overflow-x: hidden;
  }
  .Toastify__toast {
    overflow-x: hidden;
  }
`;
