import { ColorType } from "../../styles/theme";
import React, { createContext, useEffect, useState } from "react";

export interface TabTheme {
  textColor: ColorType;
  backgroundColor: ColorType;
  highlightColor: ColorType;
  tabsBottomBorder?: {
    enabled?: boolean;
    color?: ColorType;
  };
}

export interface TabsPlatform {
  enabled?: boolean;
  color?: ColorType;
}

export interface TabProviderProps {
  theme?: TabTheme;
  mobile?: boolean;
  selectedTabID?: string;
  onTabChange?: (selectedTabID: string) => void;
}

export interface TabContextInput {
  selectedTab: string;
  theme: TabTheme;
  mobile: boolean;
  selectTab: (tabValue: string) => void;
  registerTab: (tabValue: string) => void;
}

export const DEFAULT_THEME: TabTheme = {
  backgroundColor: "SLATE200",
  highlightColor: "SLATE400",
  textColor: "SLATE800",
  tabsBottomBorder: {
    enabled: false,
    color: "SLATE100",
  },
};

export const TabContext = createContext<TabContextInput>({
  selectedTab: "",
  theme: DEFAULT_THEME,
  mobile: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectTab: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerTab: () => {},
});

export const TabProvider: React.FC<TabProviderProps> = ({
  children,
  theme,
  mobile,
  selectedTabID,
  onTabChange,
}) => {
  const [poolOfTabs, setPoolOfTabs] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState("");

  const selectTab = (tabValue: string) => {
    setSelectedTab(tabValue);

    if (onTabChange) {
      onTabChange(tabValue);
    }
  };

  // All this poolOfTabs and registerTab thing is to make sure
  // we can automatically select the first tab as the default
  const registerTab = (tabValue: string) => {
    setPoolOfTabs((prevState) => [...prevState, tabValue]);
  };

  useEffect(() => {
    if (!selectedTabID) {
      const [firstTab] = poolOfTabs;

      setSelectedTab(firstTab);
    } else {
      setSelectedTab(selectedTabID);
    }
  }, [poolOfTabs.length]);

  return (
    <TabContext.Provider
      value={{
        selectedTab,
        selectTab,
        registerTab,
        theme: theme || DEFAULT_THEME,
        mobile: Boolean(mobile),
      }}
    >
      {children}
    </TabContext.Provider>
  );
};
