import AuthActionTypes from "./auth.types";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { navigate } from "gatsby";
import { toast } from "react-toastify";
import { AuthUser, LoginInput } from "@ifgengineering/client-auth-sdk";
import { authSdk } from "@utils/auth";
import { localStorageTutorialKey } from "../../layouts/layout";
import { logException } from "@helpers/logException/logException";
import { ResetPasswordInput } from "@ifgengineering/client-auth-sdk";
import { AppleCallbackInput } from "@ifgengineering/client-auth-sdk/src/types";

export const signInAsync = createAsyncThunk(
  AuthActionTypes.SIGN_IN,
  async (
    { email, password, otp, token, captchaToken }: LoginInput,
    { rejectWithValue }
  ) => {
    try {
      const result = await authSdk.login({
        email,
        password,
        otp,
        token,
        captchaToken,
      });
      localStorage.removeItem("apple_id_token");
      return result.data;
    } catch (e: any) {
      if (e && e.response && e.response.data.statusCode === 429) {
        toast.error(e.response.data.message);
      }

      logException({
        scopeEmail: email,
        tag: "verifyOTP",
        exception: e,
      });

      return rejectWithValue(e);
    }
  }
);

const DONT_REPORT_HTTP_STATUSES = [401, 403, 409];

export const signUpAsync = createAsyncThunk<
  void,
  { email: string; password: string; captchaToken: string }
>(
  AuthActionTypes.SIGN_UP,
  async ({ email, password, captchaToken }, { rejectWithValue }) => {
    try {
      await authSdk.signup({ email, password, captchaToken });
      localStorage.setItem(localStorageTutorialKey, "false");
    } catch (e: any) {
      if (e && e.response && e.response.data.statusCode === 429) {
        toast.error(e.response.data.message);
      } else if (
        !DONT_REPORT_HTTP_STATUSES.some(
          (status) => status === e.response.data.statusCode
        )
      ) {
        logException({
          scopeEmail: email,
          tag: "signup",
          exception: e,
        });
      }

      return rejectWithValue(e);
    }
  }
);

export const signOutAsync = createAsyncThunk(
  AuthActionTypes.SIGN_OUT,
  async (_, { rejectWithValue }) => {
    try {
      await authSdk.logout();
      navigate("/app/login");
    } catch (e: any) {
      logException({
        tag: "logout",
        exception: e,
      });

      return rejectWithValue(JSON.stringify(e));
    }
  }
);

export const getUserInfoAsync = createAsyncThunk(
  AuthActionTypes.GET_AUTH_ACCOUNT_DATA,
  async (_, { rejectWithValue }) => {
    try {
      const response = await authSdk.userInfo();

      return response.user;
    } catch (e: any) {
      if (e.response.status !== 401) {
        logException({
          tag: "userInfo",
          exception: e,
        });
      }

      return rejectWithValue(e);
    }
  }
);

export const updateUserInfo = createAction<AuthUser>(
  AuthActionTypes.UPDATE_USER
);

export const resetAuth = createAction(AuthActionTypes.RESET);

export const resetPasswordAsync = createAsyncThunk(
  AuthActionTypes.RESET_PASSWORD,
  async (
    { email, newPassword, otp, token }: ResetPasswordInput,
    { rejectWithValue }
  ) => {
    try {
      const response = await authSdk.resetPassword({
        email,
        newPassword,
        otp,
        token,
      });
      toast.success("You successfully changed your password!");
      navigate("/app/login");
      return response;
    } catch (e: any) {
      if (e.response.status === 401) {
        toast.error(
          "Your reset password link has expired. Please regenerate a link via the forgot password page."
        );
      } else {
        logException({
          scopeEmail: email,
          tag: "userInfo",
          exception: e,
        });

        toast.error("Something went wrong! Try again later.");
      }
      return rejectWithValue(e);
    }
  }
);

export const updateTwoFactorAuthAsync = createAsyncThunk<any, any>(
  AuthActionTypes.UPDATE_TWO_FACTOR_AUTH,
  async (
    { isTwoFactorAuthEnabled, primaryTwoFactorAuthMethod },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await authSdk.updateTwoFactorAuthSettings({
        isTwoFactorAuthEnabled,
        primaryTwoFactorAuthMethod,
      });
      toast.success("Security details updated successfully!");
      dispatch(getUserInfoAsync());
      return response;
    } catch (e) {
      logException({
        tag: "updateTwoFactorAuthSettings",
        exception: e,
      });

      toast.error("Something went wrong! Try again later.");
      return rejectWithValue(e);
    }
  }
);

export const signInGoogleAsync = createAsyncThunk<any, any>(
  AuthActionTypes.SIGN_IN_GOOGLE,
  async ({ authorizationCode }, { rejectWithValue }) => {
    try {
      await authSdk.googleLogin({
        authorizationCode,
      });
      localStorage.removeItem("apple_id_token");
      navigate(`/app/deals`);
    } catch (e) {
      logException({
        tag: "signInGoogle",
        exception: e,
      });
      toast.error("Something went wrong! Try again later.");
      return rejectWithValue(e);
    }
  }
);

export const signInAppleAsync = createAsyncThunk<any, AppleCallbackInput>(
  AuthActionTypes.SIGN_IN_APPLE,
  async ({ authorizationCode, appleIdToken }, { rejectWithValue }) => {
    try {
      const response = await authSdk.appleCallback({
        authorizationCode,
        appleIdToken,
      });
      const isPrivateRelayEmail = response.data?.isPrivateRelayEmail;
      const email = response.data?.email;
      if (isPrivateRelayEmail) {
        localStorage.removeItem("apple_id_token");
        navigate("/app/signup-apple");
        return { isPrivateRelayEmail };
      }

      if (email) {
        localStorage.removeItem("apple_id_token");
        navigate("/app/deals");
        return { email };
      }
    } catch (e) {
      logException({
        tag: "signInApple",
        exception: e,
      });
      toast.error("Something went wrong! Try again later.");
      return rejectWithValue(e);
    }
  }
);

export const signupAppleAsync = createAsyncThunk<any, any>(
  AuthActionTypes.SIGN_UP_APPLE,
  async ({ authorizationCode }, { rejectWithValue }) => {
    try {
      const response = await authSdk.appleSignup({
        authorizationCode,
      });
      if (response.data?.email) {
        localStorage.removeItem("apple_id_token");
        navigate("/app/deals");
      }
    } catch (e) {
      logException({
        tag: "signupApple",
        exception: e,
      });
      toast.error("Something went wrong! Try again later.");
      return rejectWithValue(e);
    }
  }
);

export const setViewedInvestorCertificateForm = () => ({
  type: AuthActionTypes.SET_VIEWED_INVESTOR_CERTIFICATE_FORM,
});
