import React, { FC } from "react";
import styled from "styled-components";
import { Text, theme, MEDIA_QUERIES } from "@ifgengineering/component-library";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 14;
  background-color: ${theme.colors.PEBBLE200};
  padding: 8px 120px;

  @media ${MEDIA_QUERIES.TAB_LAND} {
    padding: 8px 32px;
  }
`;

const Link = styled(Text)`
  text-decoration: underline;
`;

const RegulatoryBanner: FC = () => {
  return (
    <Container>
      <Text color="SLATE800" type="P16">
        Don’t invest unless you’re prepared to lose all the money you invest.
        This is a high‑risk investment and you’re unlikely to be protected if
        something goes wrong.{" "}
        <Link
          color="SLATE800"
          type="P14"
          as="a"
          href="https://cur8.capital/risk-warning"
          target="_blank"
        >
          Take 2 mins to learn more
        </Link>
        .
      </Text>
    </Container>
  );
};

export default RegulatoryBanner;
