import React from "react";
import Cur8MiniLogo from "../assets/Cur8-8.png";
import TopBar from "./TopBar";
import {
  MobileIconContainer,
  Container,
  DropDownContainer,
  MobileMenuContainer,
  MobilePageContainer,
  NavMobileContainer,
  StyledMobileImage,
} from "./styled";
import { OutlinedButton } from "@ifgengineering/component-library";
import { iconMap, pageIconMap } from "./constants";
import Icon, { IconType } from "@icon-park/react/es/all";
import { theme } from "@ifgengineering/component-library";
import { MenuTemplateItem, WealthMainPageType } from "../types";
import { NavMenuItemsPosition } from "../constants";
import MenuItems from "./MenuItems";

type MobileNavbarProps = {
  children: JSX.Element | Promise<void>;
  template: MenuTemplateItem[];
  isOpen?: boolean;
  page: WealthMainPageType;
  hideTopBar?: boolean;
  toggleMenu?: () => void;
  logoutCallback: () => void;
  mobileInnerNav?: () => JSX.Element | null;
  modalContext?: React.Context<{
    show: boolean;
    toggleModal: () => void;
  }>;
};

const MobileNavbar: React.FC<MobileNavbarProps> = ({
  children,
  template,
  isOpen,
  page,
  toggleMenu,
  logoutCallback,
  hideTopBar,
  mobileInnerNav,
  modalContext,
}) => {
  const topMenu = template.filter(
    ({ position }) => position === NavMenuItemsPosition.MAIN
  );
  const bottomMenu = template.filter(
    ({ position }) => position === NavMenuItemsPosition.SECONDARY
  );

  return (
    <Container>
      <NavMobileContainer>
        <StyledMobileImage src={Cur8MiniLogo} />
        <MobileMenuContainer>
          <OutlinedButton
            text={page}
            icon={pageIconMap[page]}
            onClick={toggleMenu}
            testId="navigation-menu-button"
          />
          {isOpen && (
            <DropDownContainer>
              <MenuItems items={topMenu} />
            </DropDownContainer>
          )}
        </MobileMenuContainer>

        <MobileIconContainer>
          {bottomMenu.map(({ tab, onClick }, i) => (
            <Icon
              key={i}
              type={iconMap[tab] as IconType}
              size={20}
              fill={theme.colors.SLATE800}
              onClick={onClick}
            />
          ))}

          <Icon
            type="logout"
            size={20}
            fill={theme.colors.SLATE800}
            onClick={logoutCallback}
            data-testid="logout-button"
          />
        </MobileIconContainer>
      </NavMobileContainer>
      {!hideTopBar && (
        <TopBar
          isMobileNav
          page={page === "Invest" ? "Cur8 Capital" : page}
          modalContext={modalContext}
        />
      )}
      {mobileInnerNav && mobileInnerNav()}
      <MobilePageContainer>{children}</MobilePageContainer>
    </Container>
  );
};

export default MobileNavbar;
