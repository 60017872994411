import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as actions from "../constants/actionTypes";
import { toast } from "react-toastify";
import { User } from "@ifgengineering/hip-app-domain";
import authSdk from "../authSDK";

export const UPDATE_APP_RECORD = "UPDATE_APP_RECORD";

export const updateUserInfo = createAction<User, string>(
  actions.UPDATE_USER_INFO
);

export const authError = createAsyncThunk<
  void,
  {
    err: Error;
  }
>(actions.AUTH_ERROR, () => {
  toast.info("Please log in");
});

export const resetPassword = async ({
  email,
  newPassword,
  token,
}: {
  email: string;
  newPassword: string;
  token: string;
}): Promise<void> => {
  return await authSdk.resetPassword({ email, newPassword, token });
};
