import React, { FC } from "react";
import OtpInput from "react-otp-input";
import { StyledInput } from "./Cur8OtpInput.styled";

interface Cur8OtpInputProps {
  value: string;
  numInputs: number;
  onChange: (otp: string) => void;
  isDisabled?: boolean;
  hasErrored?: boolean;
  errorMessage?: string;
}

export const Cur8OtpInput: FC<Cur8OtpInputProps> = ({
  value,
  numInputs,
  onChange,
  isDisabled,
  hasErrored,
}) => (
  <OtpInput
    value={value}
    onChange={(otp: string) => {
      onChange(otp);
    }}
    shouldAutoFocus
    numInputs={numInputs}
    renderInput={(props) => (
      <StyledInput {...props} hasErrored={hasErrored} disabled={isDisabled} />
    )}
  />
);
