import { logException } from "@helpers/logException/logException";
import axios from "axios";
import getRefreshToken from "./getRefreshToken";
import store from "@state/store";
import { signOutAsync } from "@state/auth/auth.actions";
import { NODE_ENV } from "gatsby-env-variables";
import { AnyAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";
const excludedEndpoints = [
  "/auth/refresh-access-token",
  "/auth/2fa/details",
  "/auth/login",
  "/auth/forgot-password/login",
  "/auth/reset-password",
];

const isExcludedEndpoint = (url: string) =>
  excludedEndpoints.some((ex) => {
    const r = new RegExp(ex, "g");
    return url.match(r);
  });

export const axiosErrorInterceptor = (): number => {
  return axios.interceptors.response.use(
    (response) => response,
    async function (e) {
      if (e.message === "Network Error" && e.response === undefined) {
        logException({
          tag: e.config.url,
          message: "CORS",
          exception: e,
        });

        return Promise.reject(e);
      }

      if (e?.response?.status === 400 || e?.response?.status >= 500) {
        logException({
          tag: "axiosErrorInterceptor",
          exception: e,
        });
      }

      const originalRequest = e.config;

      if (!e.response) {
        return Promise.reject(e);
      }

      if (
        e.response?.status === 401 &&
        isExcludedEndpoint(originalRequest.url)
      ) {
        return Promise.reject(e);
      }

      if (e.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const res = await getRefreshToken();

        if (res && res?.status === 201) {
          return axios(originalRequest);
        }

        if (res && res?.status === 401) {
          return Promise.reject(e);
        }
      }

      // When running the integration test
      // the address search will return 401 so often the interceptor will log the user out
      // making the tests failing
      const isTheGoogleSearch = originalRequest.url.match(/query\?place/gi);
      const isAValidationIssue =
        e.response?.status >= 400 || e.response?.status < 500;

      if (
        !isAValidationIssue &&
        (NODE_ENV !== "development" || !isTheGoogleSearch)
      ) {
        store.store.dispatch(signOutAsync() as unknown as AnyAction);
      }

      return Promise.reject(e);
    }
  );
};

export const axiosRequestInterceptor = (): number => {
  return axios.interceptors.request.use((config) => {
    const activeSpan = Sentry.getActiveSpan();
    const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;

    const sentryTraceHeader = rootSpan
      ? Sentry.spanToTraceHeader(rootSpan)
      : undefined;

    const sentryBaggageHeader = rootSpan
      ? Sentry.spanToBaggageHeader(rootSpan)
      : undefined;

    config.headers["sentry-trace"] = sentryTraceHeader;
    config.headers["baggage"] = sentryBaggageHeader;

    return config;
  });
};
