import React, { FC, useEffect, useState, useContext } from "react";
import {
  Text,
  Input,
  FilledButton as Button,
  theme,
} from "@ifgengineering/component-library";
import { Field, Formik, Form } from "formik";
import { BackContainer, Container, Link } from "../styled";
import * as Yup from "yup";
import Icon from "@icon-park/react/es/all";
import { navigate } from "@reach/router";
import { AuthComponentsContext } from "../../../Context";

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
  form: {
    errors: {
      email: string;
    };
    touched: {
      email: string;
    };
  };
};

export const Schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

type CheckYourEmailProps = {
  contactURL: string;
};

const CheckYourEmail: FC<CheckYourEmailProps> = ({ contactURL }) => (
  <>
    <Text type="T32" color="SLATE800" fontFamily="archiasemibold">
      Check your email!
    </Text>
    <Text type={"P16"} color="SLATE800" fontFamily="archialight">
      If your e-mail exists in our database you will receive a email containing
      a link to reset password. Please also check your junk folder as it
      sometimes appears here.
    </Text>
    <Text type={"P16"} color="SLATE800" fontFamily="archialight">
      If you are still unable to login, please check the email inputted or{" "}
      <Link href={contactURL} target="_blank">
        contact our support.
      </Link>
    </Text>
  </>
);

interface ForgotPasswordProps {
  loginURL?: string;
  contactURL?: string;
  showFormSubmitted?: boolean;
  onSubmit?: (email: string) => void;
}

const ForgotPassword: FC<ForgotPasswordProps> = ({
  loginURL = "/auth/signin",
  contactURL = "https://www.islamicfinanceguru.com/contact-us",
  onSubmit,
  showFormSubmitted,
}) => {
  const { useAnalytics, sendResetPasswordLink } = useContext(
    AuthComponentsContext
  );
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const fireForgotPasswordAnalytics = useAnalytics(
    "forogtPasswordSubmitEmailClick"
  );

  useEffect(() => {
    if (showFormSubmitted !== undefined) {
      setIsLinkSent(showFormSubmitted);
    }
  }, [showFormSubmitted]);

  const handleSubmit = async (values: { email: string }) => {
    const email = values.email.trim().toLocaleLowerCase();

    if (onSubmit) {
      (await fireForgotPasswordAnalytics)({
        email,
      });
      onSubmit(email);
      return;
    } else {
      try {
        await sendResetPasswordLink(email);
        setIsLinkSent(true);
      } catch (e: any) {
        if (e.response.status === 404) {
          setError("We couldn't find this email in our database.");
        }
        console.error(e);
      }
      setIsLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={Schema}
      initialValues={{ email: "" }}
      onSubmit={handleSubmit}
    >
      <Form>
        <Container>
          {isLinkSent ? (
            <CheckYourEmail contactURL={contactURL} />
          ) : (
            <>
              <BackContainer
                onClick={() => {
                  navigate(loginURL);
                }}
              >
                <Icon
                  type="ArrowLeft"
                  size={18}
                  theme="outline"
                  fill={theme.colors.BLUE600}
                />
                <Text type="S16" color="BLUE600" fontFamily="Inter">
                  Back
                </Text>
              </BackContainer>
              <Text type="T32" color="SLATE800" fontFamily="archiasemibold">
                {"Let's get your account back!"}
              </Text>
              <Field name="email">
                {({ field, form: { errors, touched } }: FieldType) => (
                  <Input
                    {...field}
                    errorMessage={
                      (touched.email ? errors.email : undefined) || error
                    }
                    testId="email-input"
                    heading="Email"
                  />
                )}
              </Field>
              <Button
                testId="submit-email-button"
                type="submit"
                height="56px"
                text="Submit Email"
                color="darkBlue"
                isLoading={isLoading}
              />
            </>
          )}
        </Container>
      </Form>
    </Formik>
  );
};

export default ForgotPassword;
